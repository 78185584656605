import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { useThemeStore } from "./context/ThemeState";
import Toast from "./context/Toast";
import Header from "./components/UI/user/Headers";

// Importing components
import SignIn from "./components/auth/SignIn";
import ForgetPassword from "./components/auth/ForgetPassword";
import ResetPassword from "./components/auth/ResetPassword";
import Journals from "./components/Journals/Journal";
import DashboardMain from "./components/user/dashboard/Dashboard";
import Trades from "./components/user/trades/Trades";
import TradesDetails from "./components/user/TradesDetails/TradesDetails";
import Symbols from "./components/user/symbols/Symbols";
import FundsTable from "./components/user/funds/Funds";
import SummaryList from "./components/user/summary/SummaryList";
import PositionManager from "./components/user/Position/PositionManager";
import FeedContainer from "./components/user/feed/FeedContainer";
import ProfilePage from "./components/user/Profile/ProfilePage";
import Register from "./components/auth/Signup";
import Home from "./components/user/Homepage/Home";
import NotFound from "./NotFound";

// Define routes as an array of objects
const routes = [
  { path: "/auth/signin", element: <SignIn /> },
  { path: "/auth/signup", element: <Register /> },
  { path: "/auth/forgetpassword", element: <ForgetPassword /> },
  { path: "/auth/resetpassword/:token", element: <ResetPassword /> },
  { path: "/journals", element: <Journals /> },
  { path: "/journal/dashboard", element: <DashboardMain /> },
  { path: "/journal/trades", element: <Trades /> },
  { path: "/journal/trades/:trades_id", element: <TradesDetails /> },
  { path: "/journal/symbols", element: <Symbols /> },
  { path: "/journal/funds", element: <FundsTable /> },
  { path: "/journal/summary", element: <SummaryList /> },
  { path: "/journal/positions", element: <PositionManager /> },
  { path: "/journal/feed", element: <FeedContainer /> },
  { path: "/journal/profile", element: <ProfilePage /> },
  { path: "/", element: <Home /> },
  { path: "*", element: <NotFound /> },
];

function App() {
  const { setTheme } = useThemeStore();

  useEffect(() => {
    const currentTheme = window.matchMedia("(prefers-color-scheme: dark)").matches
      ? "dark"
      : "light";

    setTheme(currentTheme);

    const handleThemeChange = (e: MediaQueryListEvent) => {
      setTheme(e.matches ? "dark" : "light");
    };

    const darkModeMediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    darkModeMediaQuery.addEventListener("change", handleThemeChange);

    return () => {
      darkModeMediaQuery.removeEventListener("change", handleThemeChange);
    };
  }, [setTheme]);

  return (
    <BrowserRouter>
      <Header />
      <Toast />
      <AppComponent />
    </BrowserRouter>
  );
}

function AppComponent() {
  const { theme } = useThemeStore();
  const { pathname } = useLocation();

  return (
    <div
      className={`min-h-screen ${pathname.startsWith("/journal/") ? "pt-14" : ""} ${theme === "light" ? "bg-white text-gray-700" : "bg-stone-950 text-gray-300"
        }`}
    >
      <Routes>
        {routes.map(({ path, element }) => (
          <Route key={path} path={path} element={element} />
        ))}
      </Routes>
    </div>
  );
}

export default App;
