import { Button, Tab, TabGroup, TabList } from "@headlessui/react";
import { useThemeStore } from "../../../context/ThemeState";
import { Cog6ToothIcon } from "@heroicons/react/24/outline";

import SummaryExplanation from "../Documentations/SummaryDocs";

const categories = [
  {
    name: "Daily",
    value: "day",
  },

  {
    name: "Monthly",
    value: "month",
  },
  {
    name: "Quartarly",
    value: "quarter",
  },
  {
    name: "Yearly",
    value: "year",
  },
];

export default function Tabs2({
  currentTab,
  setCurrentTab,
  setClose,
  setIsOpenHelp,
  isOpen,
}: {
  currentTab:
  | { name: "Daily"; value: "day" }
  | { name: "Monthly"; value: "month" }
  | { name: "Quartarly"; value: "quarter" }
  | { name: "Yearly"; value: "year" };
  setCurrentTab: (
    value:
      | { name: "Daily"; value: "day" }
      | { name: "Monthly"; value: "month" }
      | { name: "Quartarly"; value: "quarter" }
      | { name: "Yearly"; value: "year" }
  ) => void;
  setClose: () => void;
  setIsOpenHelp: (value: boolean) => void;
  isOpen: boolean;
}) {
  const { theme } = useThemeStore();

  return (
    <div
      className={`flex w-full justify-between  py-3  ${theme === "dark" ? "bg-stone-950" : "bg-white"
        }`}
    >
      <div className="max-w-md">
        <TabGroup
          className={`py-1 px-1 rounded-lg ${theme === "light" ? "bg-gray-100" : "bg-stone-800"
            }`}
        >
          <TabList className="flex md:gap-3">
            {categories.map((item: any) => (
              <Tab
                key={item}
                onClick={() => setCurrentTab(item)}
                className={({ selected }) => {
                  console.log(selected);
                  return `rounded-lg py-1 font-semibold focus:outline-none md:px-2 px-1
                  ${selected
                      ? theme === "dark"
                        ? "bg-stone-800 shadow"
                        : "bg-white shadow"
                      : ""
                    }
                  ${theme === "dark" ? "text-white" : "text-gray-800"} 
                  hover:${theme === "dark" ? "bg-stone-700" : "bg-gray-100"}`;
                }}
              >
                {item.name}
              </Tab>
            ))}
          </TabList>
        </TabGroup>
      </div>
      <div className="flex items-center gap-3">
        <SummaryExplanation />
        <Button
          onClick={() => setIsOpenHelp(!isOpen)}
          className={`inline-flex items-center gap-2 rounded-md  py-1 px-3 font-semibold  data-[focus]:outline-1  focus:outline-none shadow-inner border ${theme === "light" ? "border-gray-300" : "border-stone-700"
            }`}
        >
          <Cog6ToothIcon className="h-5 w-5" />
          <p className="md:block hidden">Settings</p>{" "}
        </Button>
      </div>
    </div>
  );
}
/***/
