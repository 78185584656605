import { useEffect, useMemo, useState } from "react";
import { ColumnList } from "./ColumnsList";
import { AgGridReact } from "ag-grid-react";
import { useThemeStore, useToastStore } from "../../../context/ThemeState";
import Tabs2 from "./Tabs2";
import localStorageHelpers from "../../../Helpers/LocalStorage";
import { SERVER_URL } from "../../../Config/Constants";

export default function SummaryList() {
  const { theme } = useThemeStore();
  const { showToast } = useToastStore();
  const headers = new Headers();
  const [currentTab, setCurrentTab] = useState<
    | { name: "Daily"; value: "day" }
    | { name: "Monthly"; value: "month" }
    | { name: "Quartarly"; value: "quarter" }
    | { name: "Yearly"; value: "year" }
  >({ name: "Monthly", value: "month" });
  headers.append("Content-Type", "application/json");
  const journal_id = localStorageHelpers.get("getdata")("journal_id");
  const [data, setData] = useState([]);
  useEffect(() => {
    (async () => {
      try {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        const response = await fetch(
          `${SERVER_URL}/api/summary/filter/${journal_id}?period=${currentTab?.value}`,
          { headers }
        );
        if (response.ok) {
          const responseData = await response.json();
          setData(responseData);
        } else {
          const responseData = await response.json();
          showToast(responseData.message, "error");
        }
      } catch (error: any) {
        showToast(error.message, "error");
      }
    })();
  }, [journal_id, currentTab, showToast]);

  const [isOpenHelp, setIsOpenHelp] = useState(false);

  const columnList: any = useMemo(() => ColumnList, [ColumnList.length]);
  return (
    <div className="px-5 pb-5">
      <Tabs2
        setCurrentTab={setCurrentTab}
        setIsOpenHelp={setIsOpenHelp}
        setClose={() => setIsOpenHelp(false)}
        isOpen={isOpenHelp}
        currentTab={currentTab}
      />
      <div
        className={` md:h-[80vh] h-[85vh] ${theme === "light"
          ? "bg-white ag-theme-alpine"
          : "bg-stone-900  ag-theme-alpine-dark"
          }`} // applying the Data Grid theme
      >
        <AgGridReact
          columnDefs={columnList}
          rowData={data}
          rowHeight={35}
          headerHeight={35}
          className={`border-gray-900`}
        />
      </div>
    </div>
  );
}
