import React from "react";

export default function DetailsContainer({ details }: { details: any }) {
  return (
    <div className="flex overflow-x-auto gap-5 px-5 pt-3">
      <CardComponent title="Capital Deployed">
        <div className="text-2xl font-bold">
          {Number(details?.capitalDeployed).toLocaleString()}
        </div>
      </CardComponent>
      <CardComponent title="Starting Account Value (Adj.)">
        <div className="text-2xl font-bold">
          {Number(details?.accountValue).toLocaleString()}
        </div>
      </CardComponent>
      <CardComponent title="Account Value">
        <div className="text-2xl font-bold">
          {Number(details?.accountValue).toLocaleString()}
        </div>
      </CardComponent>
      <CardComponent title="Exposure">
        <div className="text-2xl font-bold">
          {Number(details?.totalExposure).toLocaleString()}
        </div>
      </CardComponent>
      <CardComponent title="Total Open Risk">
        <div className="text-2xl font-bold">
          {Number(details?.totalOpenRisk).toLocaleString()}
        </div>
      </CardComponent>
      <CardComponent title="Unrealised Profit">
        <div></div>
      </CardComponent>
    </div>
  );
}

function CardComponent({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) {
  return (
    <div className="rounded-lg border bg-gray-200 text-gray-700 shadow-sm flex-grow">
      <div className="flex p-3 text-sm font-bold whitespace-nowrap">
        {title}
      </div>
      <div className="p-3 pt-0">{children}</div>
    </div>
  );
}
