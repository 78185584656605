import React from "react";
import { TypeButtons, TypeConverter } from "./TypeConverter";
import { useThemeStore } from "../../../context/ThemeState";

type data = {
  Feed: FeedCardProps;
};

interface FeedCardProps {
  id: string;
  order: { id: string };
  date: string;
  symbol: any;
  quantity: number;
  price: number;
  author: {
    id: string;
    email: string;
  };
  type: any;
}

const FeedCard: React.FC<data> = ({ Feed }) => {
  const { theme } = useThemeStore();
  const formattedDate = new Date(Feed?.date).toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });

  return (
    <div
      className={`rounded-lg border shadow-sm ${
        theme === "light"
          ? "bg-gray-100 border-stone-300"
          : "bg-stone-900 border-stone-800"
      }`}
    >
      <div className="flex p-3 text-sm font-bold whitespace-nowrap flex-col space-y-1.5 =">
        <TypeConverter type={Feed.type} symbol={Feed.symbol?.symbol} />
        <div className="text-sm text-gray-500">
          <div className="flex gap-2">
            <span>{formattedDate},</span>
            <span>{Feed?.author?.email}</span>
          </div>
        </div>
      </div>
      <div className="p-3 pt-0">
        <div className="flex mb-2 gap-2">
          <p>Quantity: {Feed.quantity}</p>
          <p>Price: {Feed.price}</p>
          <p>Stoploss: 483.3</p>
          <p>Charges: 88.97</p>
        </div>
        <TypeButtons orderId={Feed?.order?.id} type={Feed.type} />
      </div>
    </div>
  );
};

export default FeedCard;
