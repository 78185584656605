import { useThemeStore } from "../../../context/ThemeState";
import { Journal } from "../../../types/types";

const Section2 = ({ Journal }: { Journal: Journal }) => {
  const { theme } = useThemeStore();
  return (
    <div className="flex my-3 gap-4 overflow-auto">
      {/* Capital Deployed Card */}
      <div
        className={`rounded-lg border shadow-sm flex-grow ${theme === "light"
          ? "border-gray-200 bg-gray-100"
          : "bg-stone-900 border-stone-800"
          }`}
      >
        <div className="flex p-3 text-sm font-bold whitespace-nowrap">
          Capital Deployed
        </div>
        <div className="p-3 pt-0">
          <div className="text-2xl">{Journal?.capitalDeployed ? Number(Journal?.capitalDeployed) : 0}</div>
        </div>
      </div>

      {/* Starting Account Value (Adj.) Card */}
      <div
        className={`rounded-lg border shadow-sm flex-grow ${theme === "light"
          ? "border-gray-200 bg-gray-100"
          : "bg-stone-900 border-stone-800"
          }`}
      >
        <div className="flex p-3 text-sm font-bold whitespace-nowrap">
          Starting Account Value (Adj.)
        </div>
        <div className="p-3 pt-0">
          <div className="text-2xl">{Journal?.capitalDeployed ? Number(Journal?.capitalDeployed) : 0}</div>
        </div>
      </div>

      {/* Account Value Card */}
      <div
        className={`rounded-lg border shadow-sm flex-grow ${theme === "light"
          ? "border-gray-200 bg-gray-100"
          : "bg-stone-900 border-stone-800"
          }`}
      >
        <div className="flex p-3 text-sm font-bold whitespace-nowrap">
          Account Value
        </div>
        <div className="p-3 pt-0">
          <div className="text-2xl flex gap-2 items-end">
            <span>{Journal?.accountValue ? Number(Journal?.accountValue) : 0}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section2;
