import { AgGridReact } from "ag-grid-react";
import { useThemeStore } from "../../../context/ThemeState";
import TabButtons from "./TabButtons";
import { Link } from "react-router-dom";
import { useMemo } from "react";

export default function PositionTable({ data }: { data: any }) {
  const { theme } = useThemeStore();

  const columnDefs: any = useMemo(
    () => [
      {
        headerName: "Symbol",
        field: "symbol",
        width: 130,
        cellRenderer: (params: any) => {
          return (
            <div>
              <Link to={`/journal/trades/${params?.data?.id}`}>
                <p className="underline">{params?.data?.symbol?.symbol}</p>
              </Link>
            </div>
          );
        },
        cellStyle: {
          borderRight: "1px solid gray",
        },
      },
      {
        headerName: "Date",
        field: "date",
        width: 150,

        cellRenderer: (params: any) => {
          const date = new Date(params.data.date);
          return date.toDateString();
        },
        cellStyle: {
          borderRight: "1px solid gray",
        },
      },
      {
        headerName: "Type",
        field: "type",
        width: 120,
        cellStyle: {
          borderRight: "1px solid gray",
        },
      },
      {
        headerName: "Open Qty",
        field: "remainingQuantity",
        width: 130,
        cellStyle: {
          borderRight: "1px solid gray",
        },
      },
      {
        headerName: "Entry Price",
        field: "price",
        width: 130,
        cellRenderer: (params: any) => {
          return params?.data?.price
            ? Number(params?.data?.price).toFixed(2)
            : "";
        },
        cellStyle: {
          borderRight: "1px solid gray",
        },
      },
      {
        headerName: "SL",
        field: "stoploss",
        width: 130,
        cellRenderer: (params: any) => {
          return (
            <div>
              {params?.data?.stoploss
                ? Number(params?.data?.stoploss).toFixed(2)
                : ""}{" "}
            </div>
          );
        },
        cellStyle: {
          borderRight: "1px solid gray",
        },
      },
      {
        headerName: "SL %",
        field: "slPercent",
        width: 130,
        cellRenderer: (params: any) => {
          return (
            <div>
              {params?.data?.slPercent
                ? Number(params?.data?.slPercent).toFixed(2)
                : ""}{" "}
              %
            </div>
          );
        },
        cellStyle: {
          borderRight: "1px solid gray",
        },
      },
      {
        headerName: "Current SL",
        field: "stoploss",
        width: 120,
        cellRenderer: (params: any) => {
          return params?.data?.stoploss
            ? Number(params?.data?.stoploss).toFixed(2)
            : "";
        },
        cellStyle: {
          borderRight: "1px solid gray",
        },
      },
      {
        headerName: "Exposure",
        field: "exposure",
        width: 130,
        cellRenderer: (params: any) => {
          return <>{Number(params?.data?.exposure).toFixed(2)}</>;
        },
        cellStyle: {
          borderRight: "1px solid gray",
        },
      },
      {
        headerName: "Exposure %",
        field: "exposure",
        width: 130,
        cellRenderer: (params: any) => {
          return (
            <>
              {(
                ((Number(params?.data?.quantity) *
                  Number(params?.data?.price)) /
                  Number(params?.data?.capital)) *
                100
              ).toFixed(2)}
              %
            </>
          );
        },
        cellStyle: {
          borderRight: "1px solid gray",
        },
      },
      {
        headerName: "Open Risk",
        field: "totalOpenRisk",
        width: 130,
        cellRenderer: (params: any) => {
          return <div>{Number(params?.data?.openRisk).toFixed(2)}</div>;
        },
        cellStyle: {
          borderRight: "1px solid gray",
        },
      },
      {
        headerName: "Open Risk %",
        field: "totalOpenRisk",
        width: 130,
        cellRenderer: (params: any) => {
          return (
            <div>
              {Number(Number(
                params?.data?.openRisk / params?.data?.deployedValue
              ) * 100).toFixed(2)}
              %
            </div>
          );
        },
        cellStyle: {
          borderRight: "1px solid gray",
        },
      },
      {
        headerName: "Net Profit",
        field: "netProfit",
        width: 130,
        cellRenderer: (params: any) => {
          return params.data?.netProfit
            ? Number(params.data?.netProfit).toFixed(2)
            : "";
        },
        cellStyle: {
          borderRight: "1px solid gray",
        },
      },
      {
        headerName: "CMP",
        field: "currentMarketPrice",
        width: 130,

        cellStyle: {
          borderRight: "1px solid gray",
        },
      },
      {
        headerName: "Unrealised Profit",
        field: "unrealisedProfit",
        width: 130,
        cellStyle: {
          borderRight: "1px solid gray",
        },
      },
    ],
    []
  );
  return (
    <div className="px-5">
      <TabButtons />
      <div
        className={` md:h-[80vw] font-semibold ${theme === "light"
            ? "bg-white ag-theme-alpine"
            : "bg-stone-900  ag-theme-alpine-dark"
          }`} // applying the Data Grid theme
        style={{ height: 500 }} // the Data Grid will fill the size of the parent container
      >
        <AgGridReact
          rowData={data?.orders}
          columnDefs={columnDefs}
          rowHeight={35}
          headerHeight={35}
        />
      </div>
    </div>
  );
}
