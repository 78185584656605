import { AgGridReact } from "ag-grid-react";
import { useEffect, useState } from "react";
import { Button } from "@headlessui/react";
import {
  DocumentTextIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { useThemeStore } from "../../../context/ThemeState";

export default function TradeDetailsTable({
  TradeDetails,
}: {
  TradeDetails: any;
}) {
  const { theme } = useThemeStore();

  // Initialize rowData with useState
  const [rowData, setRowData] = useState<any[]>([
    {
      type: TradeDetails.type,
      date: TradeDetails.date,
      days: TradeDetails.days,
      price: TradeDetails.price,
      quantity: TradeDetails.quantity,
      charges: TradeDetails.charges,
      profit: TradeDetails.profit,
      rmultiple: TradeDetails.rrr,
      isEntry: true,
    },
  ]);

  useEffect(() => {
    if (TradeDetails?.closedOrders?.length > 0) {
      const closedOrdersData = TradeDetails.closedOrders.map((index: any) => ({
        type: "EXIT",
        date: index?.date,
        days: index?.days,
        price: index?.price,
        quantity: index?.quantity,
        charges: index?.charges,
        profit: index?.profit,
        rmultiple: index.rrr,
        isEntry: false,
      }));

      // Append closedOrdersData to existing rowData
      setRowData((prevData) => [TradeDetails, ...closedOrdersData]);
    } else {
      setRowData((prevData) => [TradeDetails]);
    }
  }, [TradeDetails?.closedOrders]);

  const columnDefs: any = [
    {
      headerName: "Type",
      field: "type",
      width: 120,
      cellStyle: {
        borderRight: "1px solid gray",
      },
    },
    {
      headerName: "Date",
      field: "date",
      width: 150,
      cellRenderer: (params: any) => {
        const date = new Date(params.data.date);
        return date.toDateString();
      },
      cellStyle: {
        borderRight: "1px solid gray",
      },
    },
    {
      headerName: "Days",
      field: "days",
      width: 120,

      cellStyle: {
        borderRight: "1px solid gray",
      },
    },
    {
      headerName: "Price",
      field: "price",
      width: 120,
cellRenderer:(params:any)=>(
  <div>{Number(params.data?.price).toFixed(0)}</div>
),
      cellStyle: {
        borderRight: "1px solid gray",
      },
    },
    {
      headerName: "Qty",
      field: "quantity",
      width: 120,

      cellStyle: {
        borderRight: "1px solid gray",
      },
    },
    {
      headerName: "Charges",
      field: "charges",
      width: 120,
      cellRenderer: (params: any) => {
        const date = Number(params.data.charges).toFixed(2);
        return date;
      },
      cellStyle: {
        borderRight: "1px solid gray",
      },
    },
    {
      headerName: "Profit",
      field: "profit",
      width: 120,
      cellRenderer: (params: any) => {
        const date = Number(params.data.profit).toFixed(2);
        return date;
      },
      cellStyle: {
        borderRight: "1px solid gray",
      },
    },
    {
      headerName: "Rmultiple",
      field: "rmultiple",
      width: 120,
      cellRenderer: (params: any) => {
        const date = params.data?.rmultiple
          ? Number(params.data?.rmultiple).toFixed(1)
          : 0;
        return <>{date}R</>;
      },
      cellStyle: {
        borderRight: "1px solid gray",
      },
    },
    {
      headerName: "Actions",
      field: "actions",
      width: 420,
      cellRenderer: (params: any) => {
        return (
          <div className="flex items-center gap-5 h-full">
            <Button
              className={`flex items-center gap-2 border h-8 rounded-lg px-4`}
            >
              <DocumentTextIcon className="h-5 w-5" />
              <p>Notes</p>
            </Button>
            <Button
              className={`flex items-center gap-2 border h-8 px-4 rounded-lg`}
            >
              <PencilSquareIcon className="h-5 w-5" />
              <p>Edit</p>
            </Button>
            <Button
              className={`flex items-center gap-2 border h-8 px-4 rounded-lg text-red-500`}
            >
              <TrashIcon className="h-5 w-5" />
              <p>Delete</p>
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <div
      className={`${
        theme === "light" ? "ag-theme-alpine" : "ag-theme-alpine-dark"
      }`}
      style={{ height: "auto", width: "100%" }}
    >
      <AgGridReact
        rowData={rowData}
        columnDefs={columnDefs}
        headerHeight={35}
        rowHeight={35}
        domLayout="autoHeight"
      />
    </div>
  );
}
