import { Button, Field, Input, Label, Radio } from "@headlessui/react";
import { useThemeStore } from "../../../context/ThemeState";
import CalculatorRadio from "./RadioChargesCalculator";
import clsx from "clsx";
import { useState } from "react";
import { TradesCalculator } from "../../../Helpers/TradesCalculator";

export default function ChargesCalculator({
  transactionPrice,
  type,
  setFormState,
  quantity = 0,
}: {
  transactionPrice: number;
  type?: "BUY" | "SELL";
  setFormState: (value: any) => void;
  quantity?: number;
}) {
  const { theme } = useThemeStore();
  const [brokerage, setBrokerage] = useState(0);
  function calculateCharges() {
    const charges = new TradesCalculator().Charges(
      brokerage,
      type ? type : "BUY",
      transactionPrice,
      quantity
    );
    setFormState((prev: any) => ({ ...prev, charges: charges }));
  }
  return (
    <>
      <div
        className={`border flex justify-left py-3 px-5 my-5 rounded-3xl w-max ${theme === "light" ? "border-gray-500/25" : "border-stone-500/40"
          }`}
      >
        <Field className={"flex flex-col"}>
          <Label className={"font-semibold text-sm"}>Symbol</Label>
          <CalculatorRadio />
          <Field>
            <Label className={"font-semibold text-sm"}>Brokerage</Label>
            <Input
              type="number"
              onChange={(e) => {
                if (!isNaN(Number(e.target.value)) && Number(e.target.value) < 0) {
                  return;
                }
                setBrokerage(Number(e.target.value));
                console.log(e.target.value);
              }}
              value={brokerage}
              className={clsx(
                `${theme === "light"
                  ? "border border-gray-500/25 bg-slate-100"
                  : "border border-gray-800/25"
                }`,
                "mt-1 block w-full rounded-lg bg-white/5 py-1.5 px-3 text-sm/6 ",
                "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-blue-500"
              )}
            />
          </Field>
          <div className="flex justify-center py-4">
            <Button
              className="inline-flex items-center gap-2 rounded-md bg-gray-700 py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white"
              onClick={calculateCharges}
            >
              Calculate Charges
            </Button>
          </div>
        </Field>
      </div>
    </>
  );
}
