import React from 'react';
import { useNavigate } from 'react-router-dom';

const NotFound: React.FC = () => {
    const navigate = useNavigate();

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-blue-50 to-white text-center">
            <div className="w-72 h-48 bg-cover bg-center mb-8"
                style={{ backgroundImage: "url('https://static.vecteezy.com/system/resources/previews/005/570/686/non_2x/boy-graphic-designer-digital-drawing-tool-flat-design-illustration-vector.jpg')" }}
                aria-label="Stock Graph Illustration"></div>
            <h1 className="text-6xl font-bold text-blue-600">404</h1>
            <p className="text-xl text-gray-700 mt-4">
                Oops! The page you're looking for doesn't exist.
            </p>
            <button
                onClick={() => navigate('/journal/dashboard')}
                className="mt-8 px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-800 transition-all"
            >
                Go Back to Dashboard
            </button>
        </div>
    );
};

export default NotFound;
