const localStorageHelpers = new Map();
// Helper functions for localStorage (wrapped in async functions)
function setItem(key: string, value: string) {
  try {
    console.log(key, value);
    localStorage.setItem(key, value);
  } catch (error) {
    console.error("Error setting item in localStorage:", error);
  }
}

function getItem(key: string) {
  try {
    // Simulating a delay or async process
    const item = localStorage.getItem(key);

    return item ? item : null;
  } catch (error) {
    console.error("Error getting item from localStorage:", error);
    return null;
  }
}

function removeItem(key: string) {
  try {
    localStorage.removeItem(key);
    return true; // Success indicator
  } catch (error) {
    console.error("Error removing item from localStorage:", error);
    return false;
  }
}

localStorageHelpers.set("storedata", (key: string, value: string) =>
  setItem(key, value)
);
localStorageHelpers.set("getdata", (key: string) => getItem(key));
localStorageHelpers.set("deletedata", (key: string) => removeItem(key));

export default localStorageHelpers;
