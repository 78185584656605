import { useCallback, useEffect, useRef, useState } from "react";
import { useThemeStore } from "../../context/ThemeState";
import { ChevronDownIcon } from "@heroicons/react/16/solid";
import SignInButton from "../UI/SignInButton";
import GenericSubmitWithLoading from "../UI/buttons/GenericSubmitWithLoading";

export default function Edit({ setIsopen }: { setIsopen: () => void }) {
  const { theme } = useThemeStore();
  return (
    <div
      className={`${
        theme === "light" ? "bg-white" : "bg-stone-900"
      } rounded-xl md:w-[400px] w-[90vw]`}
    >
      <div
        className={
          theme === "light"
            ? "bg-gray-300 rounded-t-xl px-5 py-1.5 font-semibold text-lg relative"
            : "bg-stone-700 rounded-t-xl px-5 py-1.5 font-semibold text-lg relative"
        }
      >
        <p>Edit Journal</p>
        <button
          className={`absolute top-0 right-0 px-5 py-1.5 text-lg rounded-tr-xl ${
            theme === "light"
              ? "bg-red-500 text-white"
              : "bg-red-500 text-white"
          }`}
          onClick={setIsopen}
        >
          X
        </button>
      </div>
      <div className="p-5 w-full">
        <form action="" className="flex flex-col gap-5 w-full">
          <div className="flex flex-col">
            <label htmlFor="" className="font-semibold text-lg">
              Name
            </label>
            <input
              type="text"
              className={`px-4 border py-2 rounded-lg focus:ring-4 outline-none ${
                theme === "light"
                  ? "border-gray-300  focus:ring-blue-100 focus:border-blue-500 bg-white"
                  : "border-stone-800 focus:ring-blue-950 focus:border-blue-500 bg-stone-950"
              }`}
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="" className="font-semibold text-lg">
              Select Country
            </label>
            <MenuIconAndDropDown
              DataArr={["USA", "India"]}
              setSelected={(item) => console.log(item)}
            />
          </div>
          <div className="px-5">
            <GenericSubmitWithLoading
              isLoading={false}
              btnName="Submit"
              btnStyles="font-semibold text-center w-full bg-blue-500 text-white py-2 px-4 rounded-lg "
            />
          </div>
        </form>
      </div>
    </div>
  );
}

interface PropsMenu<T> {
  setSelected: (state: string) => void;
  DataArr?: T[];
  defaultVal?: string;
  position?: string;
  disabled?: boolean;
}

function MenuIconAndDropDown<T>({
  setSelected,
  DataArr,
  defaultVal,
  position,
  disabled = false,
}: PropsMenu<T>) {
  const [isSelectionOpen, toggleSelection] = useState(false);
  const { theme } = useThemeStore();
  const menuRef: any = useRef();
  const [selectedOption, setSelectedOption] = useState("");
  const [modalStyle, setModalStyle] = useState({
    transform: "scale(0.95)",
    opacity: 0,
  });
  useEffect(() => {
    if (defaultVal) {
      setSelectedOption(defaultVal);
    }
  }, [defaultVal]);
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    if (isSelectionOpen) {
      // Open modal animation
      setTimeout(() => {
        setModalStyle({
          transform: "scale(1)",
          opacity: 1,
        });
      }, 50); // Delay the transition slightly for better visual effect
    } else {
      // Close modal animation
      setModalStyle({
        transform: "scale(0.95)",
        opacity: 0,
      });
      setTimeout(() => {
        setIsClosing(false);
      }, 3000); // Adjust this duration according to your transition duration
    }
  }, [isSelectionOpen]);

  const closeModal = useCallback(() => {
    setIsClosing(true);
    toggleSelection(false);
  }, [toggleSelection]);

  // Attach click outside listener
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        closeModal();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [toggleSelection, closeModal]);

  return (
    <div className="relative inline-block text-left w-full" ref={menuRef}>
      <button
        type="button"
        className={`flex items-center justify-between border px-2 py-2 rounded-lg gap-5 w-full focus:ring-4 outline-none focus:border font-semibold ${
          theme === "light"
            ? `border-gray-300 bg-white focus:ring-blue-100 focus:border-blue-600 ${
                disabled ? "text-gray-400" : "text-black"
              }`
            : `border-stone-800 bg-stone-950 focus:ring-blue-950 focus:border-blue-600 ${
                disabled ? "text-gray-400" : "text-while"
              }`
        }`}
        id="options-menu"
        aria-haspopup="true"
        aria-expanded="true"
        onClick={() => toggleSelection(!isSelectionOpen)}
        disabled={disabled}
      >
        {selectedOption === "" ? "Select" : selectedOption}
        <ChevronDownIcon className="h-5 w-5" />
      </button>
      {isSelectionOpen && (
        <div
          className={`origin-top-left absolute font-semibold text-lg z-[10000] ${
            position === "up" ? "bottom-0 mb-12" : "mt-2 right-0"
          } w-full shadow-lg rounded-lg ${
            theme === "light"
              ? "bg-white border-gray-300"
              : "bg-stone-900 border border-stone-800"
          } ring-1 ring-black ring-opacity-5 focus:outline-none py-1 px-1`}
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
          style={{
            ...modalStyle,
            transition: "transform 0.2s ease-out, opacity 0.2s ease-out",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          {DataArr && DataArr?.length > 0 ? (
            <ul className={`flex flex-col gap-3 overflow-y-auto `} role="none">
              {DataArr?.map((item: any, index: number) => (
                <li
                  key={index}
                  onClick={() => {
                    setSelectedOption(item);
                    setSelected(item);
                    toggleSelection(false);
                  }}
                  className={`px-2 py-1.5 rounded-lg ${
                    item === selectedOption && "bg-blue-300"
                  } ${
                    theme === "light"
                      ? "hover:bg-gray-100 "
                      : "hover:bg-stone-700"
                  }`}
                >
                  {item}
                </li>
              ))}
            </ul>
          ) : (
            <ul>
              <p>No data to show</p>
            </ul>
          )}
        </div>
      )}
    </div>
  );
}
