import { useEffect, useState } from "react";
import HeaderJournals from "../UI/Journals/Header/Header";
import { useThemeStore, useToastStore } from "../../context/ThemeState";
import JournalList from "./JournalList";
import cookies from "../../Helpers/Cookies";
import { useNavigate } from "react-router-dom";
import { SERVER_URL } from "../../Config/Constants";

export default function Journals() {
  const [journalsArr, setJournalsArr] = useState([]);
  const { theme } = useThemeStore();
  const { showToast } = useToastStore();
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const user = cookies.get("getuser")();
    if (!user) {
      navigate("/auth/signin");
    }
    (async () => {
      try {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        const response = await fetch(
          `${SERVER_URL}/api/journals/user/${user?.id}`,
          { headers }
        );
        if (response.ok) {
          const responseData = await response.json()
          setJournalsArr(responseData?.content);
        } else {
          const responseData = await response.json()
          showToast(responseData?.message || responseData?.title, "error");

        }
      } catch (error: any) {
        showToast(error.message || error.title, "error");
      }
    })();
  }, [isOpen]);
  return (
    <div>
      <HeaderJournals setClosing={() => setIsOpen(false)} />
      <div className="flex flex-col mt-5">
        {journalsArr?.length > 0 ? (
          journalsArr?.map(JournalList)
        ) : (
          <div className="flex flex-col items-center justify-center">
            <div className="relative w-max">
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSkDYuly_7b21gTEa4XPSIjg-dJpbtDbgrE4aKw4DCejax1I3QzwH3f5JJ1vzqLz8hVPbo&usqp=CAU"
                alt="Journal"
                className="rounded-full opacity-25"
              />
              <p
                className={`"absolute w-full flex items-center justify-center top-0 bottom-0 text-2xl font-extrabold ${theme === "light" ? " text-gray-700" : "text-gray-200"
                  }`}
              >
                404
              </p>
            </div>
            <div className="flex flex-col items-center gap-2 font-semibold font-sans">
              <p>No Journals To Show</p> <p>Please Create One</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
