import { FormEvent, useRef, useState } from "react";
import { useThemeStore } from "../../context/ThemeState";
import SignInButton from "../UI/SignInButton";
import { CallBacks } from "../../Config/Callbacks";
import { redirect, useNavigate, useParams } from "react-router-dom";
import Redirecting from "./RedirectingPage";
import toastContainer from "../../context/ToastConfiguration";
import { SERVER_URL } from "../../Config/Constants";

export default function ResetPassword() {
  const { token } = useParams();
  const theme = useThemeStore((state) => state.theme);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const router = useNavigate();
  const formRef = useRef<HTMLFormElement>(null);
  const [requestStatus, setRequestStatus] = useState({
    type: "",
    message: "",
  });

  async function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    try {
      const formData = {
        password: formRef.current?.password.value,
        confirmPassword: formRef.current?.confirmPassword.value,
      };
      if (!formData.password || !formData.confirmPassword) {
        setRequestStatus({
          type: "WARN",
          message: "password and confirm password fields cannot be empty",
        });
        return;
      }
      if (formData.password !== formData.confirmPassword) {
        setRequestStatus({
          type: "WARN",
          message: "password and confirm password fields should match",
        });
        return;
      }
      setIsLoading(true);
      const Callbacks = new CallBacks();
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      const response = await Callbacks.POST(
        `${SERVER_URL}/api/auth/resetpassword/${token}`,
        {
          headers: headers,
          body: JSON.stringify(formData),
        }
      );
      setIsSuccess(true);
      if (response) {
        setTimeout(() => {
          setIsSuccess(false);
          router("/auth/signin", { state: { fromDashboard: true } });
        }, 1000);
      }
      setRequestStatus({
        type: "SUCCESS",
        message: response?.message,
      });
    } catch (error: any) {
      setRequestStatus({
        type: "ERROR",
        message: error?.message || error?.title,
      });
    } finally {
      setIsLoading(false);
    }
  }
  return (
    <div
      className={`flex items-center justify-between h-screen w-screen ${
        theme === "light"
          ? "bg-white text-white"
          : "bg-stone-950 text-stone-200"
      }`}
    >
      <div className="fixed h-screen w-screen flex justify-center items-center backdrop-blur-sm ">
        <form
          className="flex flex-col items-center lg:w-[400px] gap-5 w-[350px] bg-black bg-opacity-75 py-10 rounded-[40px]"
          onSubmit={handleSubmit}
          ref={formRef}
        >
          <div className="mx-5">
            {
              requestStatus?.type !== "" &&
                toastContainer.get(requestStatus.type.toUpperCase())({
                  message: requestStatus.message,
                  close: () => setRequestStatus({ message: "", type: "" }),
                })
              // <p
              //   className={` px-5 py-2.5 rounded-lg  ${
              //     requestStatus?.type === "ERROR"
              //       ? "bg-red-950 border border-red-700 text-red-500 text-sm"
              //       : requestStatus?.type === "WARN"
              //       ? `bg-yellow-950 border border-yellow-700 text-yellow-500 text-sm`
              //       : "bg-green-950 border border-green-700 text-green-500 text-sm"
              //   }`}
              // >
              //   {requestStatus.message}
              // </p>
            }
            <h1 className="min-w-[300px] font-bold text-3xl">Reset Password</h1>
            <p className="text-gray-400 max-w-[330px]">
              You should fill the New Password and Confirm password{" "}
            </p>
          </div>
          <div className="flex flex-col justify-center gap-3">
            <label htmlFor="password" className="font-[500]">
              Password
            </label>
            <input
              type="text"
              className={`px-5 py-1.5 border rounded-lg min-w-[300px] outline-none bg-stone-900 border-stone-700 focus:ring-4 focus:ring-cyan-950 focus:border-cyan-600 `}
              name="password"
              id="password"
              placeholder="*********"
            />
          </div>
          <div className="flex flex-col justify-center gap-3">
            <label htmlFor="confirmPassword" className="font-[500]">
              Confirm Password
            </label>
            <input
              type="text"
              className={`px-5 py-1.5 border rounded-lg min-w-[300px] outline-none bg-stone-900 border-stone-700 focus:ring-4 focus:ring-cyan-950 focus:border-cyan-600 `}
              name="confirmPassword"
              id="confirmPassword"
              placeholder="*********"
            />
          </div>

          <div>
            <SignInButton isLoading={isLoading} btnName="Get Reset Link" />
          </div>
        </form>
      </div>
      <div>
        <img
          src="https://miro.medium.com/v2/resize:fit:602/1*xrC5eS4VJs59Sm8ZooQgHA.jpeg"
          alt="Stock Market"
          className="h-screen w-screen object-cover"
        />
      </div>
      <Redirecting isSuccess={isSuccess} />
    </div>
  );
}
