import { Radio, RadioGroup } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import { Country, Plan } from "../../../../../types/types";
import { useThemeStore } from "../../../../../context/ThemeState";

export default function PriceCard({
  plans,
  formState,
  setFormState,
}: {
  plans: Plan[];
  formState: any;
  setFormState: (value: any) => void;
}) {
  const { theme } = useThemeStore();
  return (
    <div className="w-full px-4">
      <div className="mx-auto w-full max-w-md">
        <RadioGroup
          value={plans[0]}
          onChange={(value: any) => {
            console.log(formState);
            setFormState((prev: any) => ({ ...prev, plan: value }));
          }}
          aria-label="Server size"
          className="space-y-2"
        >
          {plans.map((plan: Plan) => (
            <Radio
              key={plan.lockInPrice}
              value={plan}
              className={`group relative flex cursor-pointer rounded-lg  py-4 px-5 shadow-md transition focus:outline-none data-[focus]:outline-1 ${
                theme === "light"
                  ? "data-[focus]:outline-gray-300 bg-gray-100 text-black"
                  : "data-[focus]:outline-white data-[checked]:bg-white/10 bg-white/5 text-white"
              }`}
            >
              <div className="flex w-full items-center justify-between">
                <div className="text-sm/6">
                  <p
                    className={`font-semibold ${
                      theme === "light" ? "text-gray-800" : "text-white"
                    }`}
                  >
                    {plan.lockInCurrency + " " + plan.lockInPrice + " /-"}
                  </p>
                  <div
                    className={`flex gap-2 ${
                      theme === "light" ? "text-gray-500" : "text-white/50"
                    }`}
                  >
                    <div>per journal . per year</div>
                  </div>
                </div>
                <CheckCircleIcon
                  className={`size-6 ${
                    theme === "light" ? "fill-gray-800" : "fill-white"
                  } opacity-0 transition group-data-[checked]:opacity-100`}
                />
              </div>
            </Radio>
          ))}
        </RadioGroup>
      </div>
    </div>
  );
}
