import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"

export default function Home() {

    const { pathname } = useLocation()
    const navigate = useNavigate()
    useEffect(() => {
        if (pathname === "/") {
            navigate("/auth/signin")
        }
    }, [])

    return (
        <div></div>
    )
}