import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useThemeStore, useToastStore } from "../../../context/ThemeState";
import { CallBacks } from "../../../Config/Callbacks";
import { Symbol } from "../../../types/types";
import { useDebounce } from "use-debounce";
import { SERVER_URL } from "../../../Config/Constants";

export default function SearchSymbols({
  formStatevalue,
  setFormState,
}: {
  formStatevalue: any;
  setFormState: (value: any) => void;
}) {
  const [query, setQuery] = useState("");
  const [value] = useDebounce(query, 300);
  const [selected, setSelected] = useState({});
  const [symbols, setSymbols] = useState<Symbol[]>([]);
  const { theme } = useThemeStore();
  const { showToast } = useToastStore();

  useEffect(() => {
    (async () => {
      try {
        const callback = new CallBacks();
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        const response = await callback.GET(
          `${SERVER_URL}/api/symbols/filter?${
            query.length > 0 ? `query=${query}` : ""
          }`,
          { headers }
        );
        setSymbols(response?.content);
      } catch (error: any) {
        showToast(error?.message, "error");
      }
    })();
  }, [value, showToast]);

  return (
    <div className="">
      <Combobox
        value={selected}
        onChange={(value: any) => {
          console.log(value);
          setSelected(value);
          setFormState((prev: any) => ({
            ...prev,
            symbol: {
              id: value?.id,
              symbol: value?.symbol,
              name: value?.name,
              industry: value?.industry,
            },
          }));
        }}
        onClose={() => setQuery("")}
      >
        <div className="relative z-[400]">
          <ComboboxInput
            className={clsx(
              `${
                theme === "light"
                  ? `${
                      query.length > 0
                        ? "text-black border-stone-500/30"
                        : "text-gray-400 border-stone-500/30"
                    }`
                  : `${
                      query.length > 0
                        ? "text-white border-stone-800/25"
                        : "text-gray-400 border-stone-800/25"
                    }`
              }`,
              "w-full rounded-lg border bg-white/5 py-1.5 pr-8 pl-3 text-sm/6",
              "outline-none data-[focus]:outline-2  data-[focus]:outline-blue-500"
            )}
            displayValue={(person: any) => person?.name}
            onChange={(event) => setQuery(event.target.value)}
            placeholder="Search..."
          />
          <ComboboxButton className="group absolute inset-y-0 right-0 px-2.5 z-[400]">
            <ChevronDownIcon className="size-4 fill-white/60 group-data-[hover]:fill-white" />
          </ComboboxButton>
        </div>

        <ComboboxOptions
          anchor="bottom"
          transition
          className={clsx(
            `w-[var(--input-width)] rounded-xl border  p-1 [--anchor-gap:var(--spacing-1)] empty:invisible z-[400] mt-2 ${
              theme === "light"
                ? "bg-white text-black border-gray-500/50"
                : "bg-stone-600 text-white border-stone-800/30"
            }`,
            "transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0"
          )}
        >
          {symbols.map((symbol) => (
            <ComboboxOption
              key={symbol.id}
              value={symbol}
              className="group flex cursor-default items-center gap-2 rounded-lg py-1.5 px-3 select-none data-[focus]:bg-white/10"
            >
              <CheckIcon className="invisible size-4 fill-white group-data-[selected]:visible" />
              <div className="text-sm/6 ">{symbol.name}</div>
            </ComboboxOption>
          ))}
        </ComboboxOptions>
      </Combobox>
    </div>
  );
}
