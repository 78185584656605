import { CreateJournalOverlay } from "./createjournal/CreateJournalPage";

export default function HeaderJournals({
  setClosing,
}: {
  setClosing: () => void;
}) {
  return (
    <div className={`px-10 py-5 flex items-center justify-between `}>
      <p className="font-semibold text-xl">My Journals</p>
      <CreateJournalOverlay setIsClosing={setClosing} />
    </div>
  );
}
