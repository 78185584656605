import { Field, Label, Radio, RadioGroup } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import { useThemeStore } from "../../../context/ThemeState";

const plans = [{ name: "Equity" }, { name: "Future" }];

export default function CalculatorRadio() {
  const [selected, setSelected] = useState(plans[0]);
  const { theme } = useThemeStore();

  return (
    <div className="w-full px-4">
      <div className="mx-auto w-full ">
        {/* <RadioGroup
          value={selected}
          onChange={setSelected}
          aria-label="Server size"
          className="flex items-center gap-5"
        >
          {plans.map((plan) => (
            <Radio
              key={plan.name}
              value={plan}
              className={`group relative flex items-center cursor-pointer rounded-lg px-5 transition focus:outline-none data-[focus]:outline-1 py-1.5 ${
                theme === "light" ? "bg-gray-400/20" : "bg-gray-400/40"
              }`}
            >
              <div className="flex w-full items-center justify-between">
                <CheckCircleIcon
                  className={`size-6 opacity-0 transition group-data-[checked]:opacity-100 border bg-red-500 ${
                    theme === "light"
                      ? "fill-black text-white border-stone-400 rounded-full"
                      : "fill-white text-black border-stone-400 rounded-full"
                  }`}
                />
                <div className="text-sm/6 ">
                  <p
                    className={`font-semibold  ${
                      theme === "light" ? "text-black" : "text-white"
                    }`}
                  >
                    {plan.name}
                  </p>
                </div>
              </div>
            </Radio>
          ))}
        </RadioGroup> */}
        <RadioGroup
          value={selected}
          onChange={setSelected}
          aria-label="Server size"
          className={"flex items-center gap-5"}
        >
          {plans.map((plan) => (
            <Field
              key={plan.name}
              disabled={!plan.name}
              className="flex items-center gap-2"
            >
              <Radio
                value={plan}
                className="group flex size-5 items-center justify-center rounded-full border bg-white data-[checked]:bg-blue-400 data-[disabled]:bg-gray-100"
              >
                <span className="invisible size-2 rounded-full bg-white group-data-[checked]:visible" />
              </Radio>
              <Label className="data-[disabled]:opacity-50">{plan.name}</Label>
            </Field>
          ))}
        </RadioGroup>
      </div>
    </div>
  );
}
