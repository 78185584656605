import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

// Sample data for the chart
const data = [
  { name: "Trade 1", count: 30, roi: 5 },
  { name: "Trade 2", count: 20, roi: -2 },
  { name: "Trade 3", count: 27, roi: 1 },
  { name: "Trade 4", count: 18, roi: -4 },
  { name: "Trade 5", count: 23, roi: 3 },
];

const Section4 = () => {
  return (
    <div className="rounded-lg border bg-white text-gray-800 shadow-sm flex-grow  md:w-[90vw] w-[90vw] overflow-x-auto">
      <div className="p-3 text-sm font-bold whitespace-nowrap flex items-center justify-between">
        <div className="flex flex-1 flex-col justify-center gap-1 px-6 py-5 sm:py-6">
          <h3 className="text-2xl font-semibold leading-none tracking-tight">
            R Distribution
          </h3>
          <p className="text-sm text-gray-700">
            Showing distribution of R multiples grouped by total trades and
            total ROI
          </p>
        </div>
        <div className="flex">
          <button
            data-active="true"
            className="relative z-30 flex flex-1 flex-col justify-center gap-1 border-t px-6 py-4 text-left even:border-l data-[active=true]:bg-gray-300 sm:border-l sm:border-t-0 sm:px-8 sm:py-6"
          >
            <span className="text-xs text-gray-500">Count</span>
            <span className="text-lg font-bold leading-none sm:text-3xl">
              99
            </span>
          </button>
          <button
            data-active="false"
            className="relative z-30 flex flex-1 flex-col justify-center gap-1 border-t px-6 py-4 text-left even:border-l data-[active=true]:bg-gray-300 sm:border-l sm:border-t-0 sm:px-8 sm:py-6"
          >
            <span className="text-xs text-gray-500">ROI</span>
            <span className="text-lg font-bold leading-none sm:text-3xl">
              -6.18%
            </span>
          </button>
        </div>
      </div>
      <div className="p-3 pt-0 flex flex-col gap-2">
        <div className="flex aspect-video justify-center text-xs h-80 w-full">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={data}>
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="count" fill="#8884d8" />
              <Bar dataKey="roi" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default Section4;
