import { AgGridReact } from "ag-grid-react"; // React Data Grid Component
import { useEffect, useState } from "react";
import { useThemeStore, useToastStore } from "../../../context/ThemeState";
import { symbols } from "../../../data/Symbols";
import { SymbolGrid } from "../../../types/types";
import CreateSymbol from "./AddSymbol2";
import { SERVER_URL } from "../../../Config/Constants";
export default function Symbols() {
  const { theme } = useThemeStore();
  const { showToast } = useToastStore();
  const [rowData, setRowData] = useState<any>([]);
  // Column Definitions: Defines the columns to be displayed.
  const [colDefs] = useState<any>([
    {
      headerName: "Symbol", field: "symbol", flex: 1, cellStyle: {
        borderleft: "1px solid gray",
        borderRight: "1px solid gray",
      },
      rowHeight: 5,
      height: 5,
      minWidth: 180
    },
    {
      headerName: "Name", field: "name", flex: 1, cellStyle: {
        borderleft: "1px solid gray",
        borderRight: "1px solid gray",
      },
      rowHeight: 5,
      height: 5,
      minWidth: 180
    },
    {
      headerName: "Sector", field: "sector", flex: 1, cellStyle: {
        borderleft: "1px solid gray",
        borderRight: "1px solid gray",
      },
      rowHeight: 5,
      height: 5,
      minWidth: 180
    },
    {
      headerName: "Industry", field: "industry", flex: 1, cellStyle: {
        borderleft: "1px solid gray",
        borderRight: "1px solid gray",
      },
      rowHeight: 5,
      height: 5,
      minWidth: 180
    },
    {
      headerName: "Orders", field: "orders", flex: 1, cellStyle: {
        borderleft: "1px solid gray",
        borderRight: "1px solid gray",
      },
      rowHeight: 5,
      height: 5,
      minWidth: 180
    },
    {
      headerName: "Notes", field: "notes", flex: 1, cellStyle: {
        borderleft: "1px solid gray",
        borderRight: "1px solid gray",
      },
      rowHeight: 5,
      height: 5,
      minWidth: 180
    },
  ]);
  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(`${SERVER_URL}/api/symbols`)
        if (response.ok) {
          const responseData = await response.json()
          setRowData(responseData)
        } else {
          const responseData = await response.json()
          showToast(responseData.message, "error")
        }

      } catch (error: any) {
        showToast(error.message, "error")

      }
    })()
  }, [])

  return (
    // wrapping container with theme & size
    <div className="px-5 pb-5">
      <CreateSymbol />
      <div
        className={` md:h-[80vw] font-semibold ${theme === "light"
          ? "bg-white ag-theme-alpine"
          : "bg-stone-900  ag-theme-alpine-dark"
          }`} // applying the Data Grid theme
        style={{ height: 500 }} // the Data Grid will fill the size of the parent container
      >
        <AgGridReact
          rowData={rowData ? rowData : []}
          columnDefs={colDefs}
          rowHeight={35}
          headerHeight={35}
        />
      </div>
    </div>
  );
}
