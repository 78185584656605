import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/16/solid";
import { useThemeStore } from "../../../context/ThemeState";
import { Link } from "react-router-dom";

export default function JournalList({ journalName }: { journalName: string }) {
  const { theme } = useThemeStore();
  return (
    <div className="top-24 md:w-60 w-44 text-right">
      <Menu>
        <MenuButton
          className={`inline-flex items-center justify-between gap-2 rounded-lg  py-1.5 px-3 text-sm/6 font-semibold focus:outline-none  data-[focus]:outline-1 md:w-[210px] w-44 ${
            theme === "light"
              ? "data-[hover]:bg-stone-100 data-[open]:bg-stone-100 data-[focus]:outline-white bg-stone-100 text-gray-700"
              : "bg-stone-800 data-[hover]:bg-stone-700 data-[open]:bg-stone-800 data-[focus]:outline-stone-700 text-white"
          }`}
        >
          {journalName}
          <ChevronDownIcon
            className={`size-4 ${
              theme === "light" ? "text-gray-800" : "fill-white/60"
            }`}
          />
        </MenuButton>
        <MenuItems
          transition
          anchor="bottom end"
          className={`w-52 origin-top-right rounded-xl border p-1 text-sm/6 mt-2  transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 z-[200] ${
            theme === "light"
              ? " border-white/25 bg-white text-gray-800 "
              : "text-white border-stone-800/35 bg-stone-800 shadow-xl shadow-stone-950"
          }`}
        >
          <MenuItem>
            <button
              className={`group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 ${
                theme === "light" ? "" : "data-[focus]:bg-white/10"
              }`}
            >
              {journalName}
              <kbd className="ml-auto hidden font-sans text-xs text-gray-400 group-data-[focus]:inline">
                ⌘D
              </kbd>
            </button>
          </MenuItem>
          <div className="my-1 h-px bg-white/5" />
          <Link to={`/journals`}>
            <MenuItem>
              <button
                className={`group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 ${
                  theme === "light"
                    ? "data-[focus]:bg-stone-100 data"
                    : "data-[focus]:bg-white/10"
                }`}
              >
                ALL Journals
                <kbd className="ml-auto hidden font-sans text-xs text-gray-400 group-data-[focus]:inline">
                  ⌘A
                </kbd>
              </button>
            </MenuItem>
          </Link>
        </MenuItems>
      </Menu>
    </div>
  );
}
