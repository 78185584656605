import { Legend } from "@headlessui/react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

const Section3 = ({ data }: { data: any }) => {
  const chartData: any = [];
  let cumulativeROI = 0; // Initialize cumulative ROI

  data.forEach((entry: any, index: number) => {
    // Calculate cumulative ROI
    cumulativeROI += entry.roi; // Update cumulative ROI
    chartData.push({
      roi: (entry.roi * 100).toFixed(2), // Individual ROI as percentage
      cumulativeROI: (cumulativeROI * 100).toFixed(2), // Cumulative ROI as percentage
      index: index,
    });
  });
  return (
    <div className="rounded-lg border bg-gray-2 text-gray-12 shadow-sm flex-grow md:w-[90vw] w-[90vw] overflow-x-auto">
      <div className="p-3 text-sm font-bold whitespace-nowrap flex items-center justify-between">
        <div className="flex flex-1 flex-col justify-center gap-1 px-6 py-5 sm:py-6">
          <h3 className="text-2xl font-semibold leading-none tracking-tight">
            Equity Curve
          </h3>
          <p className="text-sm text-gray-11">
            Showing your account growth over time
          </p>
        </div>
        <div className="flex">
          <button
            data-active="false"
            className="relative z-30 flex flex-1 flex-col justify-center gap-1 border-t px-6 py-4 text-left even:border-l sm:border-l sm:border-t-0 sm:px-8 sm:py-6"
          >
            <span className="text-xs text-muted-foreground">Net Profit</span>
            <span className="text-lg font-bold leading-none sm:text-3xl">
              -73,772
            </span>
          </button>
          <button
            data-active="true"
            className="relative z-30 flex flex-1 flex-col justify-center gap-1 border-t px-6 py-4 text-left even:border-l sm:border-l sm:border-t-0 sm:px-8 sm:py-6"
          >
            <span className="text-xs text-muted-foreground">ROI</span>
            <span className="text-lg font-bold leading-none sm:text-3xl">
              -6.18%
            </span>
          </button>
        </div>
      </div>
      <div className="p-3 pt-0 flex flex-col gap-2">
        <ResponsiveContainer width="100%" height={400}>
          <LineChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="index" />
            <YAxis
              label={{ value: "ROI (%)", angle: -90, position: "insideLeft" }}
            />
            <Tooltip formatter={(value, name) => [`${value}%`, name]} />
            <Legend />
            <Line
              type="monotone"
              dataKey="roi"
              stroke="#82ca9d"
              name="Individual ROI"
            />
            <Line
              type="monotone"
              dataKey="roi"
              stroke="#ff7300"
              name="Cumulative ROI"
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default Section3;
