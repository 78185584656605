import { Button } from "@headlessui/react";
import { useThemeStore } from "../../context/ThemeState";
import ICONGOOGLE from "../UI/Icons/Journals/GoogleIcon";

export default function GoogleAuth() {
  const { theme } = useThemeStore();
  return (
    <a href={"/api/auth/google/login"}>
      <Button
        type="button"
        className={`flex items-center justify-center gap-5 min-w-[300px] px-4 py-1.5 rounded-lg font-semibold shadow ${
          theme === "light"
            ? "bg-white text-black border"
            : "bg-white text-black"
        }`}
      >
        <ICONGOOGLE height={5} width={5} />
        Sign In With Google
      </Button>
    </a>
  );
}
