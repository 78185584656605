import { Button, Checkbox, Field, Label } from "@headlessui/react";
import PlusIcon from "../../UI/Icons/Journals/PlusIcon";
import { useThemeStore } from "../../../context/ThemeState";
import { useEffect, useState } from "react";
import { CheckIcon } from "@heroicons/react/16/solid";

export default function SideControll() {
  const { theme } = useThemeStore();
  const [formState, setFormState] = useState({
    type: [
      "ORDER",
      "CLOSE_ORDERS",
      "NOTE",
      "NOTE_JOURNAL",
      "CHART",
      "CHART_JOURNAL",
    ],
  });
  const [eventTypes, setEventTypes] = useState(getInitialEventTypes);

  // Function to get initial event types from the current URL when component mounts
  function getInitialEventTypes() {
    const searchParams = new URLSearchParams(window.location.search);
    const types = searchParams.get("eventType");
    return types ? types.split(",") : [];
  }

  // Update the URL when eventTypes state changes
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    if (eventTypes.length > 0) {
      params.set("eventType", eventTypes.join(",")); // Set the eventType param
    } else {
      params.delete("eventType"); // Remove the param if no event types are selected
    }

    const newUrl = `${window.location.pathname}?${params.toString()}`;
    window.history.replaceState(null, "", newUrl); // Update the URL without reloading
  }, [eventTypes]);

  const events = [
    { type: "ORDER", name: "Open Trades" },
    { type: "CLOSE_ORDERS", name: "Exit Trades" },
    { type: "NOTE", name: "Trades Notes" },
    { type: "NOTE_JOURNAL", name: "Journal Notes" },
    { type: "CHART", name: "Trade Charts" },
    { type: "CHART_JOURNAL", name: "Journal Charts" },
  ];

  const handleCheckboxChange = (type: string) => {
    setEventTypes(
      (prev) =>
        prev.includes(type)
          ? prev.filter((t) => t !== type) // Remove if already selected
          : [...prev, type] // Add if not selected
    );
    setFormState((prevState) => {
      const isSelected = prevState.type.includes(type);
      return {
        ...prevState,
        type: isSelected
          ? prevState.type.filter((t) => t !== type)
          : [...prevState.type, type],
      };
    });
  };

  return (
    <div className=" flex flex-col gap-3">
      <div className="md:fixed left-0 p-3 top-14">
        <div>
          <Button
            className={`flex items-center border text-sm font-semibold px-2 py-1.5 rounded-lg gap-2 ${
              theme === "light" ? "border-gray-300" : "border-stone-700"
            }`}
          >
            <PlusIcon styleString="h-5 w-5" />
            Add Journal Note
          </Button>
        </div>
        <div>
          <Field>
            <Label
              className={`font-medium text-lg ${
                theme === "light" ? "text-black" : "text-white"
              }`}
            >
              Events
            </Label>
            <div className="flex flex-col gap-2">
              {events.map((item, index) => (
                <div className="flex items-center gap-3" key={index}>
                  <Checkbox
                    checked={formState.type.includes(item.type)}
                    onChange={() => handleCheckboxChange(item.type)}
                    className={`group size-6 rounded-md bg-white/10 p-1 ring-1 ring-white/15 ring-inset data-[checked]:bg-white ${
                      formState.type.includes(item.type)
                        ? theme === "light"
                          ? "border-stone-400 border"
                          : "border border-stone-800"
                        : theme === "light"
                        ? "border border-stone-300"
                        : "border border-stone-800"
                    }`}
                  >
                    <CheckIcon className="hidden size-4 fill-black group-data-[checked]:block" />
                  </Checkbox>
                  <p
                    className={`font-semibold ${
                      theme === "light" ? "text-black" : "text-white"
                    }`}
                    onClick={() => handleCheckboxChange(item.type)}
                  >
                    {item.name}
                  </p>
                </div>
              ))}
            </div>
          </Field>
        </div>
      </div>
    </div>
  );
}
