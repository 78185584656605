export const ColumnList = [
  {
    headerName: "period",
    field: "period",
    width: 120,
    cellStyle: {
      textAlign: "center",
    },
    // cellRenderer: (param: any) => {
    //   return new Date(param).toString()
    // }
  },
  {
    headerName: "OpenTrades",
    field: "openOrders",
    width: 120,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    headerName: "New Trades",
    field: "newOrders",
    width: 120,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    headerName: "Fully Closed",
    field: "fullyClosed",
    width: 120,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    headerName: "Partially Closed",
    field: "partiallyClosed",
    width: 120,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    headerName: "Win Rate %",
    field: "winRate",
    width: 120,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    headerName: "Avg RPT",
    field: "avgRPT",
    width: 120,
    cellStyle: {
      textAlign: "center",
    },
    cellRenderer: (params: any) => (
      <div>{Number(params.data?.avgRPT).toFixed(0)}</div>
    )
  },
  {
    headerName: "Avg RPT %",
    field: "avgRPTPercent",
    width: 120,
    cellStyle: {
      textAlign: "center",
    },
    cellRenderer: (params: any) => (
      <div>{Number(params.data?.avgRPTPercent).toFixed(2)} %</div>
    )
  },
  {
    headerName: "Avg Loss",
    field: "avgLoss",
    width: 120,
    cellStyle: {
      textAlign: "center",
    },
    cellRenderer: (params: any) => (
      <div>{Number(params.data?.avgLoss).toFixed(0)}</div>
    )
  },
  {
    headerName: "Avg Loss %",
    field: "avgLossPercent",
    width: 120,
    cellStyle: {
      textAlign: "center",
    },
    cellRenderer: (params: any) => (
      <div>{Number(params.data?.avgLossPercent).toFixed(2)} %</div>
    )
  },
  {
    headerName: "Avg Loss Days",
    field: "avgLossDays",
    width: 120,
    cellStyle: {
      textAlign: "center",
    },

  },
  {
    headerName: "Avg Gain",
    field: "avgGain",
    width: 120,
    cellStyle: {
      textAlign: "center",
    },
    cellRenderer: (params: any) => (
      <div>{Number(params.data?.avgGain).toFixed(0)}</div>
    )
  },
  {
    headerName: "Avg Gain %",
    field: "avgGainPercent",
    width: 120,
    cellStyle: {
      textAlign: "center",
    },
    cellRenderer: (params: any) => (
      <div>{Number(params.data?.avgGainPercent).toFixed(2)}%</div>
    )
  },
  {
    headerName: "Avg Gain Days",
    field: "avgGainDays",
    width: 120,
    cellStyle: {
      textAlign: "center",
    },

  },
  {
    headerName: "ARR",
    field: "arr",
    width: 120,
    cellStyle: {
      textAlign: "center",
    },
    cellRenderer: (params: any) => (
      <div>{Number(params.data?.arr).toFixed(2)}</div>
    )
  },
  {
    headerName: "Avg Days",
    field: "avgDays",
    width: 120,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    headerName: "Profit",
    field: "profit",
    width: 120,
    cellStyle: {
      textAlign: "center",
    },
    cellRenderer: (params: any) => (
      <div>{Number(params.data?.profit).toFixed(0)}</div>
    )
  },
  {
    headerName: "Charges",
    field: "charges",
    width: 120,
    cellStyle: {
      textAlign: "center",
    },
    cellRenderer: (params: any) => (
      <div>{Number(params.data?.charges).toFixed(0)}</div>
    )
  },
  {
    headerName: "Net Profit",
    field: "netProfit",
    width: 120,
    cellStyle: {
      textAlign: "center",
    },
    cellRenderer: (params: any) => (
      <div>{Number(params.data?.netProfit).toFixed(0)}</div>
    )
  },
  {
    headerName: "Account Value",
    field: "accountValue",
    width: 120,
    cellStyle: {
      textAlign: "center",
    },
    cellRenderer: (params: any) => (
      <div>{Number(params.data?.accountValue).toFixed(0)}</div>
    )
  },
  {
    headerName: "Capital Deployed",
    field: "capitalDeployed",
    width: 120,
    cellStyle: {
      textAlign: "center",
    },
    cellRenderer: (params: any) => (
      <div>{Number(params.data?.capitalDeployed).toFixed(0)}</div>
    )
  },
  {
    headerName: "RoCD",
    field: "RoCD",
    width: 120,
    cellStyle: {
      textAlign: "center",
    },

  },
  {
    headerName: "Adjusted Account Value",
    field: "capitalDeployed",
    width: 120,
    cellStyle: {
      textAlign: "center",
    },
    cellRenderer: (params: any) => (
      <div>{Number(params.data?.accountValue).toFixed(0)}</div>
    )
  },
  {
    headerName: "RoSAV",
    field: "RoSAV",
    width: 120,
    cellStyle: {
      textAlign: "center",
    },
  },
];
