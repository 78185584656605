import { Link } from "react-router-dom";
import { useThemeStore } from "../../../context/ThemeState";
import {
  DASHBOARD_ICON,
  FEEDS_ICON,
  FUNDS_ICON,
  POSITIONS_ICON,
  SIGNOUT_ICON,
  SUMMARY_ICON,
  SYMBOLS_ICON,
  TRADES_ICON,
} from "./IconConstantsMenu";

export default function DrawerNavigation({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const { theme } = useThemeStore();
  return (
    <div className={`${isOpen ? "bg-black w-screen z-[1300] h-screen fixed top-0 bg-opacity-25" : ""}`}>
      <div
        id="drawer-navigation"
        className={`fixed top-0 left-0 z-40 h-screen p-4 overflow-y-auto shadow-lg transition-transform ${isOpen ? "" : "-translate-x-full"
          } ${theme === "light" ? "bg-white" : "bg-stone-900"} w-64`}
        aria-labelledby="drawer-navigation-label"
      >
        <h5
          id="drawer-navigation-label"
          className={`text-base font-semibold  uppercase  ${theme === "light" ? "text-gray-800" : "text-gray-200"
            }`}
        >
          Menu
        </h5>
        <button
          type="button"
          aria-controls="drawer-navigation"
          onClick={onClose}
          className={`rounded-lg text-sm w-8 h-8 absolute top-2.5 end-2.5 inline-flex items-center justify-center ${theme === "light" ? "text-gray-800" : "text-gray-200"
            }`}
        >
          <svg
            className="w-3 h-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
          <span className="sr-only">Close menu</span>
        </button>
        <div className="py-4 overflow-y-auto">
          <ul className="space-y-2 font-medium flex flex-col gap-3">
            <Link to={"/journal/dashboard"} onClick={() => onClose()}>
              <li>
                <a
                  href="#"
                  aria-disabled
                  className={`flex items-center p-2 text-gray-900 rounded-lg ${theme === "light" ? "hover:text-white hover:bg-gray-800" : 'text-white hover:text-black  hover:bg-white'} group`}
                >
                  <DASHBOARD_ICON />
                  <span className="flex-1 ms-3 whitespace-nowrap">Dashboard</span>
                </a>
              </li>
            </Link>
            <Link to={"/journal/trades"} onClick={() => onClose()}>
              <li className="">
                <a
                  href="#"
                  aria-disabled
                  className={`flex items-center p-2 text-gray-900 rounded-lg ${theme === "light" ? "hover:text-white hover:bg-gray-800" : 'text-white hover:text-black  hover:bg-white'} group`}
                >
                  <TRADES_ICON />
                  <span className="flex-1 ms-3 whitespace-nowrap">
                    Trades
                  </span>
                </a>
              </li>
            </Link>
            <Link to={"/journal/positions"} onClick={() => onClose()}>
              <li>
                <a
                  href="#"
                  aria-disabled
                  className={`flex items-center p-2 text-gray-900 rounded-lg ${theme === "light" ? "hover:text-white hover:bg-gray-800" : 'text-white hover:text-black  hover:bg-white'} group`}
                >
                  <POSITIONS_ICON />
                  <span className="flex-1 ms-3 whitespace-nowrap">Positions</span>
                </a>
              </li>
            </Link>
            <Link to={"/journal/summary"} onClick={() => onClose()}>
              <li>
                <a
                  href="#"
                  aria-disabled
                  className={`flex items-center p-2 text-gray-900 rounded-lg ${theme === "light" ? "hover:text-white hover:bg-gray-800" : 'text-white hover:text-black  hover:bg-white'} group`}
                >
                  <SUMMARY_ICON />
                  <span className="flex-1 ms-3 whitespace-nowrap">Summary</span>
                </a>
              </li>
            </Link>
            <Link to={"/journal/funds"} onClick={() => onClose()}>
              <li>
                <a
                  href="#"
                  aria-disabled
                  className={`flex items-center p-2 text-gray-900 rounded-lg ${theme === "light" ? "hover:text-white hover:bg-gray-800" : 'text-white hover:text-black  hover:bg-white'} group`}
                >
                  <FUNDS_ICON />
                  <span className="flex-1 ms-3 whitespace-nowrap">Funds</span>
                </a>
              </li>
            </Link>
            <Link to={"/journal/symbols"} onClick={() => onClose()}>
              <li>
                <a
                  href="#"
                  aria-disabled
                  className={`flex items-center p-2 text-gray-900 rounded-lg ${theme === "light" ? "hover:text-white hover:bg-gray-800" : 'text-white hover:text-black  hover:bg-white'} group`}
                >
                  <SYMBOLS_ICON />
                  <span className="flex-1 ms-3 whitespace-nowrap">Symbols</span>
                </a>
              </li>
            </Link>
            <Link to={"/journal/feed"} onClick={() => onClose()}>
              <li>
                <a
                  href="#"
                  aria-disabled
                  className={`flex items-center p-2 text-gray-900 rounded-lg ${theme === "light" ? "hover:text-white hover:bg-gray-800" : 'text-white hover:text-black  hover:bg-white'} group`}
                >
                  <FEEDS_ICON />
                  <span className="flex-1 ms-3 whitespace-nowrap">Feed</span>
                </a>
              </li>
            </Link>
            <li>
              <a
                href="/api/auth/logout"
                aria-disabled
                className={`flex items-center p-2 text-gray-900 rounded-lg ${theme === "light" ? "hover:text-white hover:bg-gray-800" : 'text-white hover:text-black  hover:bg-white'} group`}
              >
                <SIGNOUT_ICON />
                <span className="flex-1 ms-3 whitespace-nowrap">Sign Out</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
