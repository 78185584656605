import { useThemeStore } from "../../context/ThemeState";
import MapIcon from "../UI/Icons/Journals/MapIcon";

export default function ExtendValidity({
  isOpen,
  children,
}: {
  isOpen: boolean;
  children: React.ReactNode;
}) {
  const { theme } = useThemeStore();
  if (isOpen) {
    return (
      <div className="fixed top-0 left-0 right-0 bottom-0 backdrop-brightness-50 flex items-center justify-center">
        {children}
      </div>
    );
  } else {
    return null;
  }
}
