import {
  Button,
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { Fragment, useState } from "react";
import { useThemeStore } from "../../../context/ThemeState";
import QuestionMark from "../../UI/Icons/Journals/QuestionMark";

export default function DashboardExplained() {
  const [isOpen, setIsOpen] = useState(false);
  const { theme } = useThemeStore()

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      <Button
        className={`text-semibold px-3 py-1.5 flex items-center border shadow-inner rounded-lg gap-2 ${theme === "light"
          ? "text-black border-gray-200"
          : "text-white border-stone-700"
          }`}
        onClick={openModal}
      >
        <QuestionMark styleString="h-5 w-5" />
        <p className="md:block hidden">Help</p>
      </Button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[2500]" onClose={closeModal}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="fixed inset-0 bg-black bg-opacity-30" />
          </TransitionChild>

          <div className="fixed inset-0 flex items-center justify-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative w-full p-6 overflow-y-scroll bg-white rounded-lg shadow-xl h-full">
                <div className="flex justify-between items-start">
                  <DialogTitle className="text-xl font-semibold leading-tight">
                    Dashboard Explained
                  </DialogTitle>
                  <button
                    onClick={closeModal}
                    className="text-gray-500 hover:text-gray-800"
                  >
                    <span className="sr-only">Close</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="h-6 w-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>

                <div className="mt-4 space-y-6 text-gray-700">
                  <p>
                    Dashboard shows the performance of your trading account
                    using relevant metrics. The metrics are updated based on
                    your trading activity.
                  </p>

                  <div>
                    <h2 className="text-lg font-semibold border-b pb-2">
                      Capital Deployed
                    </h2>
                    <p>
                      Capital Deployed refers to the capital brought in for
                      trading.
                    </p>
                    <code className="block bg-gray-100 p-2 rounded-md">
                      = Total Funds added till date
                    </code>
                  </div>

                  <div>
                    <h2 className="text-lg font-semibold border-b pb-2">
                      Starting Account Value
                    </h2>
                    <p>
                      Starting Account Value refers to the account value at the
                      start of the calendar year (1st Jan).
                    </p>
                    <code className="block bg-gray-100 p-2 rounded-md">
                      = Net funds added till 31 Dec + Realised profits till 31
                      Dec
                    </code>
                  </div>

                  <div>
                    <h2 className="text-lg font-semibold border-b pb-2">
                      Starting Account Value (Adj.)
                    </h2>
                    <code className="block bg-gray-100 p-2 rounded-md">
                      = Starting Account Value (1st Jan) + Net funds added in
                      the current year till date
                    </code>
                  </div>

                  <div>
                    <h2 className="text-lg font-semibold border-b pb-2">
                      Total Exposure
                    </h2>
                    <p>
                      Total value of all open positions at their cost prices.
                    </p>
                    <code className="block bg-gray-100 p-2 rounded-md">
                      = Sum of exposure of all open trades.
                    </code>
                  </div>

                  <div>
                    <h2 className="text-lg font-semibold border-b pb-2">
                      Total Exposure %
                    </h2>
                    <p>
                      Percentage of current account value that is
                      exposed/invested in the market.
                    </p>
                    <code className="block bg-gray-100 p-2 rounded-md">
                      = Sum of Exposure % of all open trades.
                    </code>
                  </div>

                  <div>
                    <h2 className="text-lg font-semibold border-b pb-2">
                      Total Open Risk
                    </h2>
                    <p>Amount of loss if all open trades fail.</p>
                    <code className="block bg-gray-100 p-2 rounded-md">
                      = Sum of open risk of all open trades
                    </code>
                  </div>

                  <div>
                    <h2 className="text-lg font-semibold border-b pb-2">
                      Total Open Risk %
                    </h2>
                    <p>
                      Percentage decrease in account value if all open trades
                      fail.
                    </p>
                    <code className="block bg-gray-100 p-2 rounded-md">
                      = Sum of open risk % of all open trades
                    </code>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
