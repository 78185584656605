import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import React from "react";
import { useThemeStore } from "../../../context/ThemeState";

export default function Drawer({
  isOpen,
  close,
  children,
  title,
}: {
  isOpen: boolean;
  close: () => void;
  children: React.ReactNode;
  title: string;
}) {
  const { theme } = useThemeStore();
  return (
    <>
      <Dialog
        open={isOpen}
        as="div"
        className="relative z-[300] focus:outline-none"
        onClose={close}
      >
        <div className="fixed inset-0 z-[300] h-screen w-screen overflow-y-auto backdrop-brightness-50 top-0">
          <div className="flex min-h-full items-center justify-center">
            <DialogPanel
              transition
              className={`w-full max-w-sm p-6 h-screen md:h-full backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0 relative shadow-2xl ml-auto ${
                theme === "light" ? "bg-white" : "bg-stone-800"
              }`}
            >
              <DialogTitle
                as="h3"
                className={`text-lg font-medium ${
                  theme === "light" ? "text-black" : "text-white"
                }`}
              >
                {title}
              </DialogTitle>
              {children}
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </>
  );
}
