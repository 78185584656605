import React, { useState } from "react";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";

const NotesDropDown = () => {
  const [selectedNotes, setSelectedNotes] = useState("Notes Only");
  const [selectedAuthor, setSelectedAuthor] = useState("All");

  return (
    <div className="flex gap-2 mt-2">
      {/* Notes NotesDropDown */}
      <Menu as="div" className="relative">
        <MenuButton className="flex h-8 items-center justify-between rounded-md border border-gray-700 bg-transparent px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-gray-700">
          Show: {selectedNotes}
          <ChevronDownIcon className="h-4 w-4 opacity-50" aria-hidden="true" />
        </MenuButton>
        <MenuItems className="absolute left-0 z-10 mt-1 w-full rounded-md border border-gray-700 bg-white shadow-lg">
          <MenuItem>
            {({ active }) => (
              <button
                className={`w-full text-left px-4 py-2 ${
                  active ? "bg-gray-200" : ""
                }`}
                onClick={() => setSelectedNotes("Notes Only")}
              >
                Notes Only
              </button>
            )}
          </MenuItem>
          <MenuItem>
            {({ active }) => (
              <button
                className={`w-full text-left px-4 py-2 ${
                  active ? "bg-gray-200" : ""
                }`}
                onClick={() => setSelectedNotes("All Notes")}
              >
                All Notes
              </button>
            )}
          </MenuItem>
          {/* Add more options as needed */}
        </MenuItems>
      </Menu>

      {/* Author NotesDropDown */}
      <Menu as="div" className="relative">
        <MenuButton className="flex h-8 items-center justify-between rounded-md border border-gray-700 bg-transparent px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-gray-700">
          Author: {selectedAuthor}
          <ChevronDownIcon className="h-4 w-4 opacity-50" aria-hidden="true" />
        </MenuButton>
        <MenuItems className="absolute left-0 z-10 mt-1 w-full rounded-md border border-gray-700 bg-white shadow-lg">
          <MenuItem>
            {({ active }) => (
              <button
                className={`w-full text-left px-4 py-2 ${
                  active ? "bg-gray-200" : ""
                }`}
                onClick={() => setSelectedAuthor("All")}
              >
                All
              </button>
            )}
          </MenuItem>
          <Menu.Item>
            {({ active }) => (
              <button
                className={`w-full text-left px-4 py-2 ${
                  active ? "bg-gray-200" : ""
                }`}
                onClick={() => setSelectedAuthor("Author A")}
              >
                Author A
              </button>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <button
                className={`w-full text-left px-4 py-2 ${
                  active ? "bg-gray-200" : ""
                }`}
                onClick={() => setSelectedAuthor("Author B")}
              >
                Author B
              </button>
            )}
          </Menu.Item>
          {/* Add more options as needed */}
        </MenuItems>
      </Menu>
    </div>
  );
};

export default NotesDropDown;
