import {
  Button,
  Tab,
  TabGroup,
  TabList,
} from "@headlessui/react";
import { useThemeStore } from "../../../context/ThemeState";
import { ChevronDownIcon } from "@heroicons/react/16/solid";
import CreateFunds from "./AddFunds2";

const categories = [
  {
    name: "Funds",
  },
  {
    name: "Adjustments",
  },
];

export default function Tabs({
  currentTab,
  setCurrentTab,
  setClose,
  setIsOpenHelp,
  isOpen,
}: {
  currentTab: "Funds" | "Adjustments";
  setCurrentTab: (value: "Funds" | "Adjustments") => void;
  setClose: () => void;
  setIsOpenHelp: (value: boolean) => void;
  isOpen: boolean;
}) {
  const { theme } = useThemeStore();

  return (
    <div
      className={`flex w-full justify-between py-3 ${theme === "dark" ? "bg-stone-950" : "bg-white"
        }`}
    >
      <div className="max-w-md">
        <div
          className={`py-1 px-1 rounded-lg ${theme === "light" ? "bg-gray-100" : "bg-stone-800"
            }`}
        >
          <div className="flex md:gap-3">
            {categories.map(({ name }) => (
              <button
                key={name}
                onClick={() => setCurrentTab(name as "Funds" | "Adjustments")}
                className={`rounded-lg py-1 font-semibold px-2 focus:outline-none
                  ${name === currentTab
                    ? theme === "dark"
                      ? "bg-stone-700 shadow text-white"
                      : "bg-white shadow text-gray-800"
                    : theme === "dark"
                      ? "text-white hover:bg-stone-700"
                      : "text-gray-800 hover:bg-gray-100"
                  }`}
              >
                {name}
              </button>
            ))}
          </div>
        </div>
      </div>
      <div className="flex items-center gap-5">
        <Button
          onClick={() => setIsOpenHelp(!isOpen)}
          className={`inline-flex items-center gap-2 rounded-md py-1.5 px-3 font-semibold focus:outline-none shadow-inner ${theme === "light"
            ? "data-[hover]:bg-gray-100 data-[open]:bg-gray-100 bg-gray-200 text-black shadow-white/70"
            : "data-[hover]:bg-gray-600 data-[open]:bg-gray-700 bg-gray-700 text-white"
            }`}
        >
          Help <ChevronDownIcon className="h-5 w-5" />
        </Button>
        <CreateFunds onClose={setClose} currentTab={currentTab} />
      </div>
    </div>
  );
}
