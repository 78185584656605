import { useEffect, useState } from "react";
import { SERVER_URL } from "../../../Config/Constants";
import localStorageHelpers from "../../../Helpers/LocalStorage";
import DetailsContainer from "./CardContainer";
import PositionTable from "./PositionTable";
import { useToastStore } from "../../../context/ThemeState";
export default function PositionManager() {
  const { showToast } = useToastStore();
  const [data, setData] = useState([]);
  useEffect(() => {
    (async () => {
      try {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        const journal_id = localStorageHelpers.get("getdata")("journal_id");
        const response = await fetch(
          `${SERVER_URL}/api/positions/journal/${journal_id}`
        );
        if (response.ok) {
          const responseData = await response.json();
          setData(responseData);
        } else {
          const responseData = await response.json();
          showToast(responseData.message, "error");
        }
      } catch (error: any) {
        showToast(error.message, "error");
      }
    })();
  }, [showToast]);
  return (
    <div>
      <DetailsContainer details={data} />
      <PositionTable data={data} />
    </div>
  );
}
