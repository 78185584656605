import {
  Button,
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { Fragment } from "react/jsx-runtime";
import QuestionMark from "../../UI/Icons/Journals/QuestionMark";
import { useThemeStore } from "../../../context/ThemeState";
import { useState } from "react";

const PositionDocs = () => {
  const { theme } = useThemeStore();
  const [isOpen, closeModal] = useState(false);
  return (
    <>
      <Button
        className={`text-semibold px-3 py-1.5 flex items-center border shadow-inner rounded-lg gap-2 ${theme === "light"
          ? "text-black border-gray-200"
          : "text-white border-stone-700"
          }`}
        onClick={() => closeModal(true)}
      >
        <QuestionMark styleString="h-5 w-5" />
        <p className="md:block hidden">Help</p>
      </Button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[400] " onClose={closeModal}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="fixed inset-0 bg-black bg-opacity-30" />
          </TransitionChild>

          <div className="fixed inset-0 flex items-center justify-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative w-full bg-white shadow-xl overflow-y-auto h-full">
                <div className="flex flex-col px-5 gap-5 md:py-5 pt-5">
                  <div>
                    <DialogTitle className="text-xl font-semibold ">
                      Position Metrics Explained
                    </DialogTitle>
                    <button
                      onClick={() => closeModal(false)}
                      className="text-gray-500 hover:text-gray-800 fixed top-0 right-0 px-5 py-5"
                    >
                      <span className="sr-only">Close</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="h-6 w-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>

                  <div>
                    <p className="mb-2">
                      The open trades page shows all the open positions, along
                      with important information like the open %, open risk,
                      exposure, etc. The open risk represents the amount of your
                      current account value that is currently at stake. This is
                      calculated for each trade as well as for all trades
                      combined. Moreover, the page lets you modify stop losses
                      of your open positions to see the effect on your open
                      risk. In addition to open risk, the page also displays
                      your total exposure in the market at present.
                    </p>

                    <h1 className="border-b pb-2 text-lg font-semibold mt-5">
                      Exposure
                    </h1>
                    <p className="flex flex-col items-start">
                      It refers to the total value of the position at its cost
                      price.
                      <code className="relative rounded bg-gray-a4 px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold">
                        = Quantity × Entry Price
                      </code>
                    </p>

                    <h1 className="border-b pb-2 text-lg font-semibold mt-5">
                      Exposure %
                    </h1>
                    <p className="flex flex-col items-start">
                      It refers to the percentage of current account value that
                      is invested in a particular position.
                      <code className="relative rounded bg-gray-a4 px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold">
                        = Quantity × Entry Price ÷ Account Value
                      </code>
                    </p>

                    <h1 className="border-b pb-2 text-lg font-semibold mt-5">
                      Total Exposure
                    </h1>
                    <p className="flex flex-col items-start">
                      It refers to the total value of all open positions at
                      their cost prices.
                      <code className="relative rounded bg-gray-a4 px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold">
                        = Sum of exposure of all open trades.
                      </code>
                    </p>

                    <h1 className="border-b pb-2 text-lg font-semibold mt-5">
                      Total Exposure %
                    </h1>
                    <p className="flex flex-col items-start">
                      It calculates the percentage of current account value that
                      is exposed/invested in the market. It essentially shows
                      your leverage.
                      <code className="relative rounded bg-gray-a4 px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold">
                        = Sum of Exposure % of all open trades.
                      </code>
                    </p>

                    <h1 className="border-b pb-2 text-lg font-semibold mt-5">
                      Open Risk
                    </h1>
                    <p className="flex flex-col items-start">
                      It refers to the amount of loss that would be incurred if
                      the trade fails.
                      <code className="relative rounded bg-gray-a4 px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold">
                        = (Entry Price - Current Stoploss) × Quantity
                      </code>
                    </p>

                    <h1 className="border-b pb-2 text-lg font-semibold mt-5">
                      Open Risk %
                    </h1>
                    <p className="flex flex-col items-start">
                      It refers to the percentage decrease in your current
                      account value if all the trades fail.
                      <code className="relative rounded bg-gray-a4 px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold">
                        = Open Risk ÷ Account Value
                      </code>
                    </p>

                    <h1 className="border-b pb-2 text-lg font-semibold mt-5">
                      Total Open Risk
                    </h1>
                    <p className="flex flex-col items-start">
                      <strong>TOR</strong> refers to the amount of loss that
                      would be incurred if all the open trades fail.
                      <code className="relative rounded bg-gray-a4 px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold">
                        = Sum of open risk of all open trades
                      </code>
                    </p>

                    <h1 className="border-b pb-2 text-lg font-semibold mt-5">
                      Total Open Risk %
                    </h1>
                    <p className="flex flex-col items-start">
                      <strong>TOR %</strong> refers to the percentage decrease
                      in your current account value if all the open trades fail.
                      <code className="relative rounded bg-gray-a4 px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold">
                        = Sum of open risk% of all open trades
                      </code>
                    </p>

                    <h1 className="border-b pb-2 text-lg font-semibold mt-5">
                      Current SL
                    </h1>
                    <p className="flex flex-col items-start">
                      By default, the current stop loss = original stop loss.
                      Use the edit function to update the current stop loss for
                      the trade to evaluate the updated open risk of that trade.
                    </p>

                    <h1 className="border-b pb-2 text-lg font-semibold mt-5">
                      CMP (Current Market Price)
                    </h1>
                    <p className="flex flex-col items-start">
                      <span>
                        Current Market Price will be updated automatically at
                        the end of the day. Manually updating the price will
                        override that.
                      </span>
                      <span>
                        If the symbol is not in our database, you can manually
                        update it.
                      </span>
                      <span>
                        CMP will be used to evaluate the{" "}
                        <strong>Unrealised Profit</strong>.
                      </span>
                    </p>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default PositionDocs;
