export default function Redirecting({ isSuccess }: { isSuccess: boolean }) {
  if (isSuccess) {
    return (
      <div className="fixed top-0 left-0 right-0 bottom-0 flex h-screen items-center justify-center backdrop-brightness-50">
        <div className="bg-white bg-opacity-20 px-5 py-4 rounded-xl">
          <p className="font-semibold text-lg ">Redirecting . . . </p>
        </div>
      </div>
    );
  } else {
    return null;
  }
}
