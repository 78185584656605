import NotesDropDown from "./NotesDropDown";
import TradingNotes from "./TradingNotes";

export default function TradingNotesContainer({
  tradeDetails,
}: {
  tradeDetails: any;
}) {
  return (
    <>
      <div className="flex justify-between items-center mt-2">
        <h3 className="text-base font-semibold leading-6">Notes</h3>
      </div>
      <NotesDropDown />
      <TradingNotes notes={tradeDetails?.notes} />
    </>
  );
}
