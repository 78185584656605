import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { Fragment } from "react";
import { useThemeStore } from "../../../context/ThemeState";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

export default function FundsType({ fundsType, setFundsType, options }: { fundsType: string, setFundsType: (type: string) => void, options: { id: string, label: string }[] }) {
    const { theme } = useThemeStore()


    const handleSelect = (option: { id: string; label: string }) => {
        setFundsType(option.label)
    };

    return (
        <div className="relative inline-block text-left">
            <Menu as="div" className="relative">
                {({ open }) => (
                    <>
                        <MenuButton className={`w-full px-4 py-1.5 flex items-center justify-between rounded-lg ${theme === "light"
                            ? "outline-none border focus:border-2 focus:border-blue-500 border-gray-300 "
                            : "outline-none border focus:border-2 focus:border-blue-500 border-stone-700 bg-black/15 text-white"
                            }`}>
                            {fundsType ? fundsType : "Select an Option"} <ChevronDownIcon className="h-5 w-5" />
                        </MenuButton>

                        {open && (
                            <MenuItems
                                as="ul"
                                className={`absolute right-0 mt-2 w-full rounded-md shadow-lg focus:outline-none ${theme === "light"
                                    ? "border focus:border-2 focus:border-blue-500 border-gray-300"
                                    : "border focus:border-2 focus:border-blue-500 border-stone-700 bg-stone-800 text-white"
                                    }`}>
                                {options.map((option: { id: string, label: string }) => (
                                    <MenuItem as={Fragment} key={option.id}>
                                        {({ active }: { active: boolean }) => (
                                            <li
                                                className={`${active ? `${theme === "light" ? "hover:bg-stone-200" : "hover:bg-stone-700"}` : `${theme === "light" ? "" : "hover:bg-stone-700"}`
                                                    } cursor-pointer px-4 py-2`}
                                                onClick={() => handleSelect(option)}
                                            >
                                                {option.label}
                                            </li>
                                        )}
                                    </MenuItem>
                                ))}
                            </MenuItems>
                        )}
                    </>

                )}
            </Menu>
        </div>
    );
}
