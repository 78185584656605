import { Button, Dialog, DialogPanel, DialogTitle, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import QuestionMark from "../../UI/Icons/Journals/QuestionMark";
import { useThemeStore } from "../../../context/ThemeState";

const SummaryExplanation = () => {
  const { theme } = useThemeStore();
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <>
      <Button
        className={`text-semibold px-3 py-1.5 flex items-center border shadow-inner rounded-lg gap-2 ${theme === "light"
          ? "text-black border-gray-200"
          : "text-white border-stone-700"
          }`}
        onClick={openModal}
      >
        <QuestionMark styleString="h-5 w-5" />
        <p className="md:block hidden">Help</p>
      </Button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[400]" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="fixed inset-0 bg-black bg-opacity-30" />
          </Transition.Child>

          <div className="fixed inset-0 flex items-center justify-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative w-full p-6 bg-white rounded-lg shadow-xl h-full overflow-y-auto">
                <div className="flex justify-between items-start mb-6">
                  <DialogTitle className="text-xl font-semibold leading-tight">
                    Summary Metrics Explained
                  </DialogTitle>
                  <button
                    onClick={closeModal}
                    className="text-gray-500 hover:text-gray-800"
                  >
                    <span className="sr-only">Close</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="h-6 w-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>

                <div className="space-y-6 text-gray-700">
                  <p>
                    The summary page shows aggregated metrics grouped by month,
                    quarter, and year. This page helps you see how your system
                    has performed over the selected period.
                  </p>

                  <div>
                    <h2 className="text-lg font-semibold border-b pb-2">
                      Open Trades
                    </h2>
                    <p>
                      The number of trades that are open at the start of the period.
                    </p>
                  </div>
                  <div>
                    <h2 className="text-lg font-semibold border-b pb-2">
                      New trades
                    </h2>
                    <p>
                      The number of new trades that are initiated during the period.
                    </p>
                  </div>
                  <div>
                    <h2 className="text-lg font-semibold border-b pb-2">
                      Fully closed

                    </h2>
                    <p>
                      The number of trades that were fully closed during the period.
                    </p>
                  </div>
                  <div>
                    <h2 className="text-lg font-semibold border-b pb-2">
                      Partially Closed

                    </h2>
                    <p>
                      The number of trades that were partially closed during the period .
                    </p>
                  </div>
                  <div>
                    <h2 className="text-lg font-semibold border-b pb-2">
                      Avg. RPT

                    </h2>
                    <p>
                      Average RPT for “fully closed” trades during the period.
                    </p>
                  </div>
                  <div>
                    <h2 className="text-lg font-semibold border-b pb-2">
                      Win Rate %

                    </h2>

                    <code className="block bg-gray-100 p-2 rounded-md">
                      Number of “fully closed” winning trades ÷ Total number of “fully closed” trades
                    </code>
                  </div>
                  <div>
                    <h2 className="text-lg font-semibold border-b pb-2">
                      Avg. Loss

                    </h2>
                    <p>
                      Average Loss per each “fully closed” trade during the period.
                    </p>
                  </div>
                  <div>
                    <h2 className="text-lg font-semibold border-b pb-2">
                      Avg. Gain

                    </h2>
                    <p>
                      Average Gain per each “fully closed” trade during the period .
                    </p>
                  </div>
                  <div>
                    <h2 className="text-lg font-semibold border-b pb-2">
                      ARR

                    </h2>
                    <code className="block bg-gray-100 p-2 rounded-md">
                      Average Risk Reward Ratio = Avg. Gain ÷ Avg. Loss
                    </code>
                  </div>
                  <div>
                    <h2 className="text-lg font-semibold border-b pb-2">
                      Profit
                    </h2>
                    <p >
                      Sum total of realised profits from “fully closed” and “partially closed” trades during the period.
                    </p>
                  </div>
                  <div>
                    <h2 className="text-lg font-semibold border-b pb-2">
                      Charges
                    </h2>
                    <p >
                      Sum total of charges incurred for “fully closed” and “partially closed” trades during the period.
                    </p>
                  </div>
                  <div>
                    <h2 className="text-lg font-semibold border-b pb-2">
                      Net Profit
                    </h2>
                    <p >
                      Profit - Charges
                    </p>
                  </div>
                  <div>
                    <h2 className="text-lg font-semibold border-b pb-2">
                      Account Value
                    </h2>
                    <p>
                      Account value as on the last day of the period .
                    </p>
                    <code className="block bg-gray-100 p-2 rounded-md">
                      = Net deposits till the last day of the period + Realised Gains till the last day of the period.
                    </code>
                  </div>
                  <div>
                    <h2 className="text-lg font-semibold border-b pb-2">
                      Capital Deployed
                    </h2>
                    <p>
                      Capital Deployed as on the last day of the period.
                    </p>
                    <code className="block bg-gray-100 p-2 rounded-md">
                      = Total Deposits - Total Withdrawals till the last day of the period
                    </code>
                  </div>

                  <div>
                    <h2 className="text-lg font-semibold border-b pb-2">
                      RoCD
                    </h2>
                    <p>
                      RoCD, which stands for Return on Capital Deployed, represents the percentage gain on your capital deployed in a given period. It is calculated as the sum total of RoCD of each “fully closed” or “partially closed” trade during the period. To calculate the RoCD of each trade, the net profit of the trade is divided by the Capital Deployed (net funds added) as of the trade's open date. ROCD and ROSV are two different metrics used to calculate returns in trading. ROCD gives the percentage return over the initial capital invested, while ROSV calculates the percentage return over the starting account value for the year, including any deposits or withdrawals made in the current year till the trade was opened. ROSV is useful in measuring the annual return based on the starting account value for that year, while ROCD calculates returns over the net funds added till trade open date
                    </p>
                    <code className="block bg-gray-100 p-2 rounded-md">
                      = sum total of RoCD of each “fully closed” or “partially closed” trade during the period
                    </code>
                  </div>

                  <div>
                    <h2 className="text-lg font-semibold border-b pb-2">
                      Adjusted Account Value
                    </h2>
                    <p>
                      It is the Adjusted Account Value as on the last day of the period.
                    </p>
                    <code className="block bg-gray-100 p-2 rounded-md">
                      = Starting Account Value (1st Jan) + Net funds added in the current year till the end of the period.
                    </code>
                  </div>

                  <div>
                    <h2 className="text-lg font-semibold border-b pb-2">
                      RoSAV
                    </h2>
                    <p>
                      RoSAV, which stands for Return on Starting Account Value, represents the percentage gain on your <strong>Starting Account Value (Adj.)</strong>for a given period. It is calculated as the sum total of RoSV of each “fully closed” or “partially closed” trade during the period. To calculate the RoSV of each trade, the net profit of the trade is divided by the Starting Account Value (Adjusted) as of the trade's open date. RoCD and RoSV are two different metrics used to calculate returns in trading. RoCD gives the percentage return over the initial capital invested, while RoSV calculates the percentage return over the starting account value for the year, including any deposits or withdrawals made in the current year till the trade was opened. RoSV is useful in measuring the annual return based on the starting account value for that year, while RoCD calculates returns over the net funds added till trade open date.
                    </p>
                    <code className="block bg-gray-100 p-2 rounded-md">
                      RoSV for a trade = Net Profit ÷ Starting Account Value as on the trade open date
                    </code>
                  </div>

                  <div>
                    <h2 className="text-lg font-semibold border-b pb-2">
                      Summary Verification steps
                    </h2>
                    <ol className="block bg-gray-100 p-2 rounded-md">
                      <li>1. Export all trades with relevant metrics.</li>
                      <li>2. In the exported excel, select everything and add filters.</li>
                      <li>3. Fully Closed Trades + Partially Closed Trades = Trade Entry with date within the period + Trade Exit with date within the period. Add a filter to the date column and select all the months that come within the respective period.</li>
                      <li>4. Fully Closed Trades = (Exit percent = 100) AND (Date of Trade Entry is within the period OR Max(Trade Exit dates) is within the period). Add a date filter, an Exit Percent 100 filter, and a max date filter.</li>
                      <li>5. Now add/count respective columns to derive various values and verify the calculations on the summary page.</li>
                    </ol>
                  </div>
                </div>
              </DialogPanel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default SummaryExplanation;
