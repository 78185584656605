// Add New Symbol
import React, { FormEvent, useRef, useState } from "react";
import ModalHeadless from "../../UI/modals/HeadlessOverlay";
import {
  Button,
  Description,
  Field,
  Fieldset,
  Input,
  Label,
} from "@headlessui/react";
import PlusIcon from "../../UI/Icons/Journals/PlusIcon";
import { useThemeStore, useTradeStore } from "../../../context/ThemeState";
import cookies from "../../../Helpers/Cookies";
import toastContainer from "../../../context/ToastConfiguration";
import localStorageHelpers from "../../../Helpers/LocalStorage";
import AddFunds from "../../UI/buttons/CreateFundsButton";
import { SERVER_URL } from "../../../Config/Constants";
import FundsType from "./WithdrawDeposite";

// Any symbol you add are private to this journal and won't be shared across your other journals.

const CreateFunds = React.memo(({ onClose, currentTab }: { onClose: () => void, currentTab: "Funds" | "Adjustments" }) => {
  const { createTrade } = useTradeStore()
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [requestStatus, setRequestStatus] = useState({
    type: "",
    message: "",
  });
  const [formState, setFormState] = useState({
    date: "",
    amount: 0,
    type: "",
  });
  const { theme } = useThemeStore();
  const formRef = useRef<HTMLFormElement>(null);
  const dateInputRef = useRef<any>(null);
  const user = cookies.get("getuser")();
  const journal_id = localStorageHelpers.get("getdata")("journal_id");

  async function handleCreateSymbol(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    try {
      const formData = {
        ...formState,
        userId: user.id,
        journalId: journal_id,
        date: formState.date
          ? new Date(formState.date).toISOString()
          : formState.date,
      };
      setIsLoading(true);
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      const response = await fetch(
        `${SERVER_URL}/api/funds/create`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(formData),
        }
      );
      if (response.ok) {
        const responseData = await response.json()
        onClose()
        createTrade()
        setRequestStatus({
          type: "success",
          message: responseData?.message,
        });
      } else {
        const responseData = await response.json()
        setRequestStatus({
          type: "error",
          message: responseData?.message,
        });
      }
    } catch (error: any) {
      setRequestStatus({
        type: "error",
        message: error.message || error?.title,
      });
    } finally {
      setIsLoading(false);
      formRef?.current?.reset();
      onClose();
    }
  }
  return (
    <>
      <Button
        className={`inline-flex items-center justify-center gap-2 rounded-md data-[focus]:outline-1 focus:outline-none py-1.5 px-3 text-sm/6 font-semibold shadow-inner  ${theme === "light"
          ? "text-gray-800 bg-gray-100"
          : " bg-stone-800 text-white"
          }`}
        onClick={() => setIsOpen(true)}
      >
        <PlusIcon styleString="h-5 w-5" />
        <span className="md:block hidden">{currentTab}</span>
      </Button>
      <ModalHeadless
        isOpen={isOpen}
        close={() => setIsOpen(false)}
        title="Add Funds"
      >
        <p className={`text-gray-400 text-sm`}>You can add some adjustments</p>
        {requestStatus?.type !== "" && (
          <div className="w-full">
            {toastContainer.get(requestStatus.type.toUpperCase())({
              message: requestStatus.message,
              close: () => setRequestStatus({ message: "", type: "" }),
            })}
          </div>
        )}
        <div className="md:w-[400px] w-full px-4">
          <form onSubmit={handleCreateSymbol} ref={formRef}>
            <Fieldset className={"flex flex-col gap-3"}>
              <Field>
                <Label
                  className={`text-sm/6 font-medium ${theme === "light" ? "text-black" : "text-white"
                    }`}
                >
                  Date
                </Label>
                <Description className={"text-sm/6 text-gray-400"}>
                  What date you added your funds
                </Description>
                <Input
                  type="date"
                  ref={dateInputRef}
                  onClick={() => dateInputRef?.current?.showPicker()}
                  className={`w-full px-4 py-1.5 rounded-lg ${theme === "light"
                    ? "outline-none border focus:border-2 focus:border-blue-500 border-gray-300 "
                    : "outline-none border focus:border-2 focus:border-blue-500 border-stone-700 bg-black/15 text-white"
                    }`}
                  onChange={(e) => {
                    setSelectedDate(e.target.value);
                    const date = new Date(e.target.value);
                    setFormState((prev) => ({
                      ...prev,
                      date: date.toISOString(),
                    }));
                  }}
                  value={selectedDate}
                />
              </Field>
              <Field className={"flex flex-col"}>
                <Label
                  className={`text-sm/6 font-medium ${theme === "light" ? "text-black" : "text-white"
                    }`}
                >
                  Type
                </Label>
                <FundsType setFundsType={(value: string) => setFormState(prev => ({ ...prev, type: value }))} fundsType={formState.type} options={currentTab === "Funds" ? [{ id: "Withdraw", label: "Withdraw" }, { id: "Deposite", label: "Deposite" }] : [{ id: "Expenses", label: "Expenses" }, { id: "Income", label: "Income" }]} />
              </Field>
              <Field>
                <Label
                  className={`text-sm/6 font-medium ${theme === "light" ? "text-black" : "text-white"
                    }`}
                >
                  Amount
                </Label>
                <Description className={"text-sm/6 text-gray-400"}>
                  Enter the amount you want to withdraw or deposite
                </Description>
                <Input
                  type="number"
                  className={`w-full px-4 py-1.5 rounded-lg ${theme === "light"
                    ? "outline-none border focus:border-2 focus:border-blue-500 border-gray-300 "
                    : "outline-none border focus:border-2 focus:border-blue-500 border-stone-700 bg-black/15 text-white"
                    }`}
                  onChange={(e) => {
                    if (isNaN(Number(e.target.value)) || Number(e.target.value) < 0) {
                      return;
                    }
                    setFormState(() => ({
                      ...formState,
                      amount: Number(e.target.value),
                    }))
                  }}
                  value={formState.amount === 0 ? "" : formState.amount}
                />
              </Field>
              <AddFunds isLoading={isLoading} />
            </Fieldset>
          </form>
        </div>
      </ModalHeadless>
    </>
  );
});
export default CreateFunds;
