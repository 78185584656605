// Add New Symbol

import React, { FormEvent, useRef, useState } from "react";
import ModalHeadless from "../../UI/modals/HeadlessOverlay";
import { Button, Field, Fieldset, Input, Label } from "@headlessui/react";
import PlusIcon from "../../UI/Icons/Journals/PlusIcon";
import { useThemeStore, useToastStore } from "../../../context/ThemeState";
import ButtonSymbol from "../../UI/buttons/CreateSymbolButton";
import cookies from "../../../Helpers/Cookies";
import { CallBacks } from "../../../Config/Callbacks";
import toastContainer from "../../../context/ToastConfiguration";

// Any symbol you add are private to this journal and won't be shared across your other journals.

const CreateSymbol = React.memo(() => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [requestStatus, setRequestStatus] = useState({
    type: "",
    message: "",
  });
  const [formState, setFormState] = useState({
    symbol: "",
    name: "",
    industry: "",
    sector: "",
  });
  const { theme } = useThemeStore();
  const { showToast } = useToastStore();
  const formRef = useRef<HTMLFormElement>(null);
  const user = cookies.get("getuser")();

  async function handleCreateSymbol(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    try {
      setIsLoading(true);
      const callback = new CallBacks();
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      const response = await callback.POST(
        "http://localhost:4000/api/symbols/create",
        {
          headers,
          body: JSON.stringify(formState),
        }
      );
      setRequestStatus({
        type: "success",
        message: response?.message,
      });
    } catch (error: any) {
      setRequestStatus({
        type: "error",
        message: error.message || error?.title,
      });
    } finally {
      setIsLoading(false);
      formRef?.current?.reset();
    }
  }
  return (
    <>
      <Button
        className={`inline-flex items-center gap-2 rounded-md data-[focus]:outline-1 focus:outline-none py-1.5 my-3 px-3 text-sm/6 font-semibold shadow-inner ${
          theme === "light"
            ? "text-gray-800 bg-gray-100"
            : "bg-stone-800 text-white"
        }`}
        onClick={() => setIsOpen(true)}
      >
        <PlusIcon styleString="h-5 w-5" /> Symbols
      </Button>
      <ModalHeadless
        isOpen={isOpen}
        close={() => setIsOpen(false)}
        title="Create Symbol"
      >
        <p className={`text-gray-400 text-sm`}>
          Any symbol you add are private to this journal and won't be shared
          across your other journals.
        </p>
        {requestStatus?.type !== "" && (
          <div className="w-full">
            {toastContainer.get(requestStatus.type.toUpperCase())({
              message: requestStatus.message,
              close: () => setRequestStatus({ message: "", type: "" }),
            })}
          </div>
        )}
        <div className="md:w-[400px] w-full px-4">
          <form onSubmit={handleCreateSymbol} ref={formRef}>
            <Fieldset className={"flex flex-col gap-3"}>
              <Field>
                <Label
                  className={`text-sm/6 font-medium ${
                    theme === "light" ? "text-black" : "text-white"
                  }`}
                >
                  Symbol
                </Label>
                <Input
                  className={`w-full px-4 py-1.5 rounded-lg ${
                    theme === "light"
                      ? "outline-none border focus:border-2 focus:border-blue-500 border-gray-300 "
                      : "outline-none border focus:border-2 focus:border-blue-500 border-stone-700 bg-black/15 text-white"
                  }`}
                  onChange={(e) =>
                    setFormState((prev) => ({
                      ...prev,
                      symbol: e.target.value,
                    }))
                  }
                  value={formState.symbol}
                />
              </Field>
              <Field>
                <Label
                  className={`text-sm/6 font-medium ${
                    theme === "light" ? "text-black" : "text-white"
                  }`}
                >
                  Name
                </Label>
                <Input
                  className={`w-full px-4 py-1.5 rounded-lg ${
                    theme === "light"
                      ? "outline-none border focus:border-2 focus:border-blue-500 border-gray-300 "
                      : "outline-none border focus:border-2 focus:border-blue-500 border-stone-700 bg-black/15 text-white"
                  }`}
                  onChange={(e) =>
                    setFormState((prev) => ({ ...prev, name: e.target.value }))
                  }
                  value={formState.name}
                />
              </Field>
              <Field>
                <Label
                  className={`text-sm/6 font-medium ${
                    theme === "light" ? "text-black" : "text-white"
                  }`}
                >
                  Sector
                </Label>
                <Input
                  className={`w-full px-4 py-1.5 rounded-lg ${
                    theme === "light"
                      ? "outline-none border focus:border-2 focus:border-blue-500 border-gray-300 "
                      : "outline-none border focus:border-2 focus:border-blue-500 border-stone-700 bg-black/15 text-white"
                  }`}
                  onChange={(e) =>
                    setFormState((prev) => ({
                      ...prev,
                      sector: e.target.value,
                    }))
                  }
                  value={formState.sector}
                />
              </Field>
              <Field>
                <Label
                  className={`text-sm/6 font-medium ${
                    theme === "light" ? "text-black" : "text-white"
                  }`}
                >
                  Industry
                </Label>
                <Input
                  className={`w-full px-4 py-1.5 rounded-lg ${
                    theme === "light"
                      ? "outline-none border focus:border-2 focus:border-blue-500 border-gray-300 "
                      : "outline-none border focus:border-2 focus:border-blue-500 border-stone-700 bg-black/15 text-white"
                  }`}
                  onChange={(e) =>
                    setFormState((prev) => ({
                      ...prev,
                      industry: e.target.value,
                    }))
                  }
                  value={formState.industry}
                />
              </Field>
              <ButtonSymbol isLoading={isLoading} />
            </Fieldset>
          </form>
        </div>
      </ModalHeadless>
    </>
  );
});
export default CreateSymbol;
