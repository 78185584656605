import { Button } from "@headlessui/react";
import { useThemeStore } from "../../../context/ThemeState";
import { ArrowDownTrayIcon } from "@heroicons/react/16/solid";
import QuestionMark from "../../UI/Icons/Journals/QuestionMark";
import GearIcon from "../../UI/Icons/Journals/GearIcon";
import PositionDocs from "../Documentations/PositionDocs";

export default function TabButtons() {
  const { theme } = useThemeStore();
  return (
    <div className="py-3 flex items-center justify-end gap-3">
      <PositionDocs />

      <Button
        className={`text-semibold px-3 py-1.5 flex items-center border shadow-inner rounded-lg gap-2 ${
          theme === "light"
            ? "text-black border-gray-200"
            : "text-white border-stone-700"
        }`}
      >
        <GearIcon styleString="h-5 w-5" />
        Settings
      </Button>
    </div>
  );
}
