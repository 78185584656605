function WARN({
  message,
  close,
  theme,
}: {
  message?: string;
  close: () => void;
  theme: "light" | "dark";
}) {
  return (
    <div>
      <div
        id="alert-4"
        className={`flex items-center p-4 mb-4 ${
          theme === "light"
            ? "bg-yellow-50 text-yellow-500"
            : "dark:bg-yellow-950 dark:text-yellow-300"
        }`}
        role="alert"
      >
        <svg
          className="flex-shrink-0 w-4 h-4"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
        </svg>
        <span className="sr-only">Info</span>
        <div className="ms-3 text-sm font-medium">{message}</div>
        <button
          type="button"
          onClick={close}
          className={`ms-auto -mx-1.5 -my-1.5 {bg-yellow-50 text-yellow-500 rounded-lg focus:ring-2  inline-flex items-center justify-center h-8 w-8 p-1.5 `}
          data-dismiss-target="#alert-4"
          aria-label="Close"
        >
          <span className="sr-only">Close</span>
          <svg
            className="w-3 h-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}

function INFO({
  message,
  close,
  theme,
}: {
  message?: string;
  close: () => void;
  theme: "light" | "dark";
}) {
  return (
    <div>
      <div
        id="alert-1"
        className={`flex items-center p-4 mb-4 ${
          theme === "light"
            ? "text-blue-800 bg-blue-50"
            : "dark:bg-gray-800 dark:text-blue-400"
        }`}
        role="alert"
      >
        <svg
          className="flex-shrink-0 w-4 h-4"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
        </svg>
        <span className="sr-only">Info</span>
        <div className="ms-3 text-sm font-medium">{message}</div>
        <button
          onClick={close}
          type="button"
          className="ms-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 inline-flex items-center justify-center h-8 w-8  dark:text-blue-400 "
          data-dismiss-target="#alert-1"
          aria-label="Close"
        >
          <span className="sr-only">Close</span>
          <svg
            className="w-3 h-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}
function ERROR({
  message,
  close,
  theme,
}: {
  message?: string;
  close: () => void;
  theme: "light" | "dark";
}) {
  return (
    <div
      id="alert-2"
      className={`flex items-center p-4 mb-4  ${
        theme === "light"
          ? "text-red-800  bg-red-50"
          : "dark:bg-red-950 dark:text-red-400"
      }`}
      role="alert"
    >
      <svg
        className="flex-shrink-0 w-4 h-4"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 20 20"
      >
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
      </svg>
      <span className="sr-only">Info</span>
      <div className="ms-3 text-sm font-medium">{message}</div>
      <button
        type="button"
        onClick={close}
        className={`ms-auto -mx-1.5 -my-1.5 rounded-lg focus:ring-2 p-1.5 inline-flex items-center justify-center h-8 w-8 text-red-500 focus:ring-red-400`}
        data-dismiss-target="#alert-2"
        aria-label="Close"
      >
        <span className="sr-only">Close</span>
        <svg
          className="w-3 h-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 14 14"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
          />
        </svg>
      </button>
    </div>
  );
}
function SUCCESS({
  message,
  close,
  theme,
}: {
  message?: string;
  close: () => void;
  theme: "light" | "dark";
}) {
  return (
    <div
      id="alert-2"
      className={`flex items-center p-4 mb-4  ${
        theme === "light"
          ? "text-green-800  bg-green-300"
          : "dark:bg-green-950 dark:text-green-400"
      }`}
      role="alert"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        className="h-5 w-5"
      >
        <path
          fillRule="evenodd"
          d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
          clipRule="evenodd"
        />
      </svg>

      <span className="sr-only">Info</span>
      <div className="ms-3 text-sm font-medium">{message}</div>
      <button
        type="button"
        onClick={close}
        className={`ms-auto -mx-1.5 -my-1.5  rounded-lg focus:ring-2 p-1.5  inline-flex items-center justify-center h-8 w-8 text-red-500 focus:ring-red-400`}
        data-dismiss-target="#alert-2"
        aria-label="Close"
      >
        <span className="sr-only">Close</span>
        <svg
          className="w-3 h-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 14 14"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
          />
        </svg>
      </button>
    </div>
  );
}

const toastContainer = new Map();

toastContainer.set("WARN", (props: any) => <WARN {...props} />);
toastContainer.set("INFO", (props: any) => <INFO {...props} />);
toastContainer.set("ERROR", (props: any) => <ERROR {...props} />);
toastContainer.set("SUCCESS", (props: any) => <SUCCESS {...props} />);

export default toastContainer;
