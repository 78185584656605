import { FormEvent, useEffect, useRef, useState } from "react";
import Drawer from "../trades/Drawer";
import { Button, Field, Fieldset, Input, Label } from "@headlessui/react";
import { useThemeStore, useToastStore } from "../../../context/ThemeState";
import clsx from "clsx";
import ButtonExit from "../../UI/buttons/CreateExitButton";
import { DocumentPlusIcon } from "@heroicons/react/16/solid";
import { CallBacks } from "../../../Config/Callbacks";
import localStorageHelpers from "../../../Helpers/LocalStorage";
import { SERVER_URL } from "../../../Config/Constants";

export default function CreateNotes({ tradeDetails }: { tradeDetails: any }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { theme } = useThemeStore();
  const { showToast } = useToastStore();
  const [formState, setFormState] = useState({
    comments: "",
    chartUrl: "",
  });
  async function handleCreateNotes(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    try {
      const callbacks = new CallBacks();
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      const formData = {
        content: formState.comments,
        chartUrl: formState.chartUrl,
        tradeId: tradeDetails?.id,
      };
      setIsLoading(true);
      const response = await callbacks.POST(
        `${SERVER_URL}/api/trades/notes/create`,
        { headers, body: JSON.stringify(formData) }
      );
      showToast(response.message, "success");
    } catch (error: any) {
      showToast(error.message, "error");
    } finally {
      setIsLoading(false);
    }
  }
  return (
    <>
      <Button
        onClick={() => setIsOpen(true)}
        className={` rounded-full px-2 py-1.5 flex items-center gap-2 ${
          theme === "light"
            ? "border bg-gray-200 border-gray-300"
            : "border bg-stone-800 border-stone-700"
        }`}
      >
        <DocumentPlusIcon className="h-5 w-5" />
        Create Notes
      </Button>
      <Drawer isOpen={isOpen} close={() => setIsOpen(false)} title="New Note">
        <div
          className={`h-screen  ${
            theme === "light" ? "text-black" : "text-white"
          }`}
        >
          <form onSubmit={handleCreateNotes}>
            <Fieldset className={"flex flex-col gap-5 mt-5"}>
              <Field>
                <Label>Comments</Label>
                <Input
                  type="text"
                  value={formState.comments}
                  onChange={(e) => {
                    setFormState(() => ({
                      ...formState,
                      comments: e.target.value,
                    }));
                  }}
                  className={clsx(
                    `${
                      theme === "light"
                        ? "border border-gray-500/30"
                        : "border border-stone-800/25"
                    }`,
                    "mt-1 block w-full rounded-lg bg-white/5 py-1.5 px-3 text-sm/6 text-white",
                    "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-blue-500"
                  )}
                />
              </Field>
              <Field>
                <Label>Chart URL</Label>
                <Input
                  type="text"
                  value={formState.chartUrl}
                  onChange={(e) => {
                    setFormState(() => ({
                      ...formState,
                      chartUrl: e.target.value,
                    }));
                  }}
                  className={clsx(
                    `${
                      theme === "light"
                        ? "border border-gray-500/30"
                        : "border border-stone-800/25"
                    }`,
                    "mt-1 block w-full rounded-lg bg-white/5 py-1.5 px-3 text-sm/6 text-white",
                    "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-blue-500"
                  )}
                />
              </Field>

              <ButtonExit isLoading={isLoading} />
            </Fieldset>
          </form>
        </div>
      </Drawer>
    </>
  );
}
