import { useEffect } from "react";
import { useThemeStore, useToastStore } from "./ThemeState";
import toastContainer from "./ToastConfiguration";

export default function Toast() {
  const { isVisible, message, type, closeToast } = useToastStore();
  const { theme } = useThemeStore();
  useEffect(() => {
    const timer = setTimeout(() => {
      closeToast();
    }, 3000);

    // Cleanup function to clear the timeout
    return () => {
      clearTimeout(timer);
    };
  }, [isVisible, closeToast]);
  if (isVisible) {
    return (
      <div className="fixed top-0 right-0 z-[1000] p-5">
        {toastContainer.get(type.toUpperCase())({
          message,
          close: () => closeToast(),
          theme,
        })}
      </div>
    );
  } else {
    return null;
  }
}
