import { FormEvent, useRef, useState } from "react";
import { useThemeStore } from "../../context/ThemeState";
import SignInButton from "../UI/SignInButton";
import { CallBacks } from "../../Config/Callbacks";
import toastContainer from "../../context/ToastConfiguration";
import { SERVER_URL } from "../../Config/Constants";

export default function ForgetPassword() {
  const theme = useThemeStore((state) => state.theme);
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const [requestStatus, setRequestStatus] = useState({
    type: "",
    message: "",
  });

  async function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    try {
      setIsLoading(true);
      const Callbacks = new CallBacks();
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      const response = await Callbacks.POST(
        `${SERVER_URL}/api/auth/forgetpassword`,
        {
          headers: headers,
          body: JSON.stringify({
            url: "http://localhost:3000",
            email: formRef.current?.username.value,
          }),
        }
      );
      setRequestStatus({
        type: "SUCCESS",
        message: response.message,
      });
    } catch (error: any) {
      setRequestStatus({
        type: "ERROR",
        message: error.message || error.title,
      });
    } finally {
      setIsLoading(false);
    }
  }
  return (
    <div
      className={`flex items-center justify-between h-screen w-screen ${
        theme === "light"
          ? "bg-white text-white"
          : "bg-stone-950 text-stone-200"
      }`}
    >
      <div className="fixed h-screen w-screen flex justify-center items-center backdrop-blur-sm ">
        <form
          className="flex flex-col items-center lg:w-[400px] gap-5 w-[350px] bg-black bg-opacity-75 py-10 rounded-[40px]"
          onSubmit={handleSubmit}
          ref={formRef}
        >
          <div>
            {requestStatus?.type !== "" && (
              <div className="w-[300px]">
                {toastContainer.get(requestStatus.type.toUpperCase())({
                  message: requestStatus.message,
                  close: () => setRequestStatus({ message: "", type: "" }),
                })}
              </div>
            )}
            <h1 className="min-w-[300px] font-bold text-3xl">
              Forgot Password
            </h1>
            <p className="text-gray-400">
              You should fill this form if you have{" "}
            </p>
            <p className="text-gray-400"> forgotten you password</p>
          </div>
          <div className="flex flex-col justify-center gap-3">
            <label htmlFor="username" className="font-[500]">
              Username
            </label>
            <input
              type="text"
              className={`px-5 py-1.5 border rounded-lg min-w-[300px] outline-none bg-stone-900 border-stone-700 focus:ring-4 focus:ring-cyan-950 focus:border-cyan-600 `}
              name="username"
              id="username"
              placeholder="xyz@example.com"
            />
          </div>

          <div>
            <SignInButton isLoading={isLoading} btnName="Get Reset Link" />
          </div>
        </form>
      </div>
      <div>
        <img
          src="https://miro.medium.com/v2/resize:fit:602/1*xrC5eS4VJs59Sm8ZooQgHA.jpeg"
          alt="Stock Market"
          className="h-screen w-screen object-cover"
        />
      </div>
    </div>
  );
}
