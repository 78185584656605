import { useThemeStore } from "../../../context/ThemeState";
import { Journal } from "../../../types/types";

const Section1 = ({ Journal }: { Journal: Journal }) => {
  const { theme } = useThemeStore();
  console.log(Journal)
  return (
    <div className="flex my-3 gap-4 overflow-auto">
      {/* Open Trades Card */}
      <div
        className={`rounded-lg border shadow-sm flex-grow ${theme === "light"
          ? "border-gray-200 bg-gray-100"
          : "border-stone-800 bg-stone-900"
          }`}
      >
        <div className="flex p-3 text-sm font-bold whitespace-nowrap justify-between">
          <span>Open Trades</span>
          <a className="text-xs underline" href="/journal/positions">
            View
          </a>
        </div>
        <div className="p-3 pt-0">
          <div className="text-2xl">{Journal?.openTradesCount ? Number(Journal.openTradesCount) : 0}</div>
        </div>
      </div>

      {/* Total Exposure Card */}
      <div
        className={`rounded-lg border shadow-sm flex-grow ${theme === "light"
          ? "border-gray-200 bg-gray-100"
          : "border-stone-800 bg-stone-900"
          }`}
      >
        <div className="flex p-3 text-sm font-bold whitespace-nowrap">
          Total Exposure
        </div>
        <div className="p-3 pt-0 flex items-end gap-2">
          <span className="text-2xl text-right">{Journal?.totalExposure ? Number(Journal?.totalExposure).toFixed(0) : 0}</span>
          <span className="text-lg">{Journal?.totalExposurePercent ? Number(Number(Journal.totalExposurePercent) * 100).toFixed(2) : 0}%</span>
        </div>
      </div>

      {/* Total Open Risk Card */}
      <div
        className={`rounded-lg border shadow-sm flex-grow ${theme === "light"
          ? "border-gray-200 bg-gray-100"
          : "border-stone-800 bg-stone-900"
          }`}
      >
        <div className="flex p-3 text-sm font-bold whitespace-nowrap">
          Total Open Risk
        </div>
        <div className="p-3 pt-0 flex items-end gap-2">
          <span className="text-2xl text-right">{Journal?.totalOpenRisk ? Number(Journal?.totalOpenRisk) : 0}</span>
          <span className="text-lg">{Journal?.totalOpenRiskPercent ? Number(Number(Journal?.totalOpenRiskPercent) * 100).toFixed(1) : 0}%</span>
        </div>
      </div>
    </div>
  );
};

export default Section1;
