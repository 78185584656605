export class TradesCalculator {
  stopLossPercent(EntryPrice: number, SL: number) {
    return (EntryPrice - SL) / EntryPrice;
  }
  positionSize(QuantityTraded: number, EntryPrice: number) {
    return QuantityTraded * EntryPrice;
  }
  positionSizePercent(positionSize: number, AccountValue: number) {
    console.log(positionSize, AccountValue);
    return (positionSize / AccountValue) * 100; //account value on the date of trading
  }
  RiskPerTrade(Quantity: number, EntryPrice: number, StopLoss: number) {
    //RPT
    return (EntryPrice - StopLoss) * Quantity; //account value on the date of trading
  }
  RiskPerTradePercent(AccountValue: number, RPT: number) {
    console.log(AccountValue, RPT);
    //RPT
    return (RPT / AccountValue) * 100; //account value on the date of trading
  }
  ExitPercent(QuantityExited: number, TotalQuantityTraded: number) {
    return (QuantityExited / TotalQuantityTraded) * 100;
  }
  GainsPercent(ExitPrice: number, EntryPrice: number) {
    const SUBSTRACTION = ExitPrice - EntryPrice;
    const FINAL = SUBSTRACTION / EntryPrice;
    return FINAL * 100;
  }
  AccountValue(CapitalDeployed: number, RealisedProfits: number) {
    return CapitalDeployed + RealisedProfits;
  }
  AccountGainPercent(NetProfit: number, AccountValue: number) {
    return NetProfit / AccountValue;
  }
  //this is another point //deployed capital total capital brought to trading
  RoCD(NetProfit: number, CapitalDeployed: number) {
    return NetProfit / CapitalDeployed * 100;
  }
  StartingAccountValue(
    Netfundsaddedtill31Dec: number,
    RealisedprofitsTill31Dec: number
  ) {
    return Netfundsaddedtill31Dec + RealisedprofitsTill31Dec;
  }
  StartingAccountValue_ADJ(
    StartingAccountValue1stJan: number,
    Netfundsaddedinthecurrentyear: number
  ) {
    return StartingAccountValue1stJan + Netfundsaddedinthecurrentyear;
  }
  RoSV(NetProfit: number, StartingAccountValue_ADJ: number) {
    return NetProfit / StartingAccountValue_ADJ;
  }
  RR(GainPercent: number, StopLossPercent: number) {
    ///risk reward ratio
    return GainPercent / StopLossPercent;
  }
  Charges(
    brokerage: number,
    type: "BUY" | "SELL",
    TrasactionPrice: number,
    quantity?: number
  ) {
    let charges = 0;
    const TRANSACTION_VALUE = quantity ? TrasactionPrice * quantity : 0;
    const TRANSACTION_CHARGES = TRANSACTION_VALUE * 0.0000325;
    const SEBI = TRANSACTION_VALUE * 0.000002;
    const STT = TRANSACTION_VALUE * 0.001;
    const GST = (SEBI + STT + brokerage) * 0.0018;
    const stamp_charges = TRANSACTION_VALUE * 0.00015;
    const DP_Charges = 15.34;
    console.table({
      TrasactionPrice,
      TRANSACTION_CHARGES,
      SEBI,
      STT,
      GST,
      stamp_charges,
      DP_Charges,
    });
    if (type === "BUY") {
      charges =
        TRANSACTION_CHARGES + SEBI + STT + GST + stamp_charges + brokerage;
    } else {
      charges = TRANSACTION_CHARGES + SEBI + STT + GST + DP_Charges + brokerage;
    }
    return Number(charges).toFixed(2);
  }
  OpenRisk(
    remainingQuantity: number,
    BuyPrice: number,
    currentStoploss: number
  ) {
    const currentStoplossValue = currentStoploss * remainingQuantity;
    const remainingBuyValue = remainingQuantity * BuyPrice;
    return currentStoplossValue - remainingBuyValue;
  }
  exposure(openQuantity: number, EntryPrice: number) {
    return openQuantity * EntryPrice;
  }

  quantityPercent(EntryQuantity: number, quantity: number) {
    return (EntryQuantity / quantity) * 100;
  }
  roFundAdded(netProfit: number, fundsAdded: number) {
    return Number(netProfit) / Number(fundsAdded);
  } //return on funds added
  // New percentage fields added
  stopLossHigherThanEntryCheck(EntryPrice: number, StopLoss: number) {
    return StopLoss > EntryPrice
      ? "Stop-loss is higher than the entry price. Possible short-sell scenario."
      : "Stop-loss is lower than the entry price.";
  }
  // Example percentage-based formula you can use in various calculations
  PercentField(value: number, base: number) {
    return (value / base) * 100;
  }
  daysCalculator(previousDate: string, todaysDate: string): number {
    // Convert the string dates into Date objects
    const previous = new Date(previousDate);
    const today = new Date(todaysDate);

    // Calculate the difference in time between the two dates in milliseconds
    const timeDifference = today.getTime() - previous.getTime();

    // Convert the time difference from milliseconds to days
    const daysDifference = timeDifference / (1000 * 3600 * 24);

    // Return the absolute difference in days to ensure it's always positive
    return Math.abs(Math.floor(daysDifference));
  }
  ROI(netProfit: number, deployedCapital: number) {
    return (Number(netProfit) / Number(deployedCapital)) * 100;
  }
}
