import { FormEvent, useMemo, useRef, useState } from "react";
import Drawer from "../trades/Drawer";
import { Button, Field, Fieldset, Input, Label } from "@headlessui/react";
import PlusIcon from "../../UI/Icons/Journals/PlusIcon";
import { useThemeStore, useToastStore } from "../../../context/ThemeState";
import clsx from "clsx";
import ChargesCalculator from "../trades/ChargesCalculator";
import ButtonExit from "../../UI/buttons/CreateExitButton";
import { CallBacks } from "../../../Config/Callbacks";
import { TradesCalculator } from "../../../Helpers/TradesCalculator";
import { SERVER_URL } from "../../../Config/Constants";

export default function ExitTrade({ tradeDetails }: { tradeDetails: any }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { theme } = useThemeStore();
  const { showToast } = useToastStore();
  const [dateShow, setDateShow] = useState("");
  const dateRef = useRef<HTMLInputElement>(null);
  const [formState, setFormState] = useState({
    date: "",
    quantity: 0,
    price: 0,
    charges: 0,
  });

  const tradesCalculator = new TradesCalculator();
  ///whatever is taken from trade details will be used for form state
  useMemo(() => {
    if (tradeDetails?.quantity) {
      setFormState((prev) => ({
        ...prev,
        quantity: Number(tradeDetails?.remainingQuantity),
        price: Number(tradeDetails?.price),
        charges: Number(tradeDetails?.charges),
      }));
    }
  }, [tradeDetails]);

  async function ExitTrade(e: FormEvent) {
    e.preventDefault();
    try {
      // Use tradesCalculator functions that are already defined
      const exitPercent = tradesCalculator?.ExitPercent(
        formState?.quantity,
        tradeDetails?.quantity
      );

      const rrPercent = tradesCalculator?.RR(
        tradeDetails?.totalGainPercent,
        tradeDetails?.slPercent
      );

      const rpt = tradesCalculator?.RiskPerTrade(
        formState?.quantity,
        tradeDetails?.price,
        tradeDetails?.stoploss
      );

      const gainPercent = tradesCalculator?.GainsPercent(
        formState?.price,
        tradeDetails?.price
      );

      // Keep percent, profit, and netProfit simple without undefined functions
      const percent =
        ((formState.price - tradeDetails.price) / tradeDetails.price) * 100;
      const profit =
        (formState.price - tradeDetails.price) * formState.quantity;
      const netProfit = profit - formState.charges;

      // Capital can be the price * quantity as a simple multiplication
      const capital = tradeDetails.price * formState.quantity;

      // ROI can be calculated as profit/capital * 100
      const roi = (netProfit / tradeDetails?.capital) * 100;
      const rrr = tradesCalculator?.RR(gainPercent, tradeDetails?.slPercent);

      // Assuming days can be set to a static value if there's no calculation method
      const days = tradesCalculator?.daysCalculator(
        tradeDetails.date,
        formState.date
      ); // Placeholder, adjust as needed
      // RO funds added can be set similarly if there's no specific calculation
      const roFundsAdded = (netProfit / tradeDetails?.capital) * 100;

      // Forming the request body with calculated values
      const formData = {
        order: tradeDetails?.id,
        date: formState.date,
        price: formState.price,
        percent, // Calculated profit percentage
        charges: formState.charges,
        quantity: formState.quantity,
        profit, // Calculated profit
        exitPercent, // Calculated exit percentage
        rrPercent, // Calculated Risk-Reward percentage
        rrr,
        days, // Static days for now
        netProfit, // Net profit after charges
        capital, // Capital used for the trade
        rpt, // Risk per trade
        gainPercent, // Gain percentage based on price difference
        roi, // Return on Investment
        roFundsAdded, // Static for now
      };
      console.log(formData);
      setIsLoading(true);
      // Sending the request
      const callbacks = new CallBacks();
      const headers = new Headers();
      headers.append("Content-Type", "application/json");

      // Uncomment this line to make the POST request
      const response = await callbacks.POST(
        `${SERVER_URL}/api/trades/close/create`,
        {
          headers,
          body: JSON.stringify(formData),
        }
      );
      showToast(response.message, "success");
    } catch (error: any) {
      showToast(error.message, "error");
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <Button
        onClick={() => setIsOpen(true)}
        className={`flex items-center gap-1.5 rounded-full px-3 py-1.5 ${theme === "light"
          ? "border bg-gray-200 border-gray-300"
          : "border bg-stone-800 border-stone-700"
          }`}
      >
        <PlusIcon styleString="h-5 w-5" />
        Exit
      </Button>
      <Drawer
        isOpen={isOpen}
        close={() => setIsOpen(false)}
        title="New Exit Trade"
      >
        <div className={` ${theme === "light" ? "text-black" : "text-white"}`}>
          <form onSubmit={ExitTrade}>
            <Fieldset className="flex flex-col gap-5">
              <Field>
                <Label>Date</Label>
                <Input
                  type="date"
                  ref={dateRef}
                  onClick={() => dateRef.current?.showPicker()}
                  value={dateShow}
                  onChange={(e) => {
                    const date = new Date(e.target.value);
                    setDateShow(e.target.value);
                    setFormState(() => ({
                      ...formState,
                      date: date.toISOString(),
                    }));
                  }}
                  className={clsx(
                    `${theme === "light"
                      ? "border border-gray-500/30 "
                      : "border border-stone-800/25"
                    }`,
                    "mt-1 block w-full rounded-lg bg-white/5 py-1.5 px-3 text-sm/6",
                    "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-blue-500"
                  )}
                  required
                />
              </Field>
              <Field>
                <Label>Quantity</Label>
                <Input
                  type="number"
                  value={formState.quantity}
                  onChange={(e) => {
                    if (!isNaN(Number(e.target.value)) && Number(e.target.value) < 0) {
                      return;
                    }
                    setFormState(() => ({
                      ...formState,
                      quantity: Number(e.target.value),
                    }));
                  }}
                  className={clsx(
                    `${theme === "light"
                      ? "border border-gray-500/30"
                      : "border border-stone-800/25"
                    }`,
                    "mt-1 block w-full rounded-lg bg-white/5 py-1.5 px-3 text-sm/6",
                    "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-blue-500"
                  )}
                  required
                />
              </Field>
              <Field>
                <Label>Price</Label>
                <Input
                  type="number"
                  value={formState.price}
                  onChange={(e) => {
                    if (!isNaN(Number(e.target.value)) && Number(e.target.value) < 0) {
                      return;
                    }
                    setFormState(() => ({
                      ...formState,
                      price: Number(e.target.value),
                    }));
                  }}
                  className={clsx(
                    `${theme === "light"
                      ? "border border-gray-500/30"
                      : "border border-stone-800/25"
                    }`,
                    "mt-1 block w-full rounded-lg bg-white/5 py-1.5 px-3 text-sm/6",
                    "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-blue-500"
                  )}
                  required
                />
              </Field>
              <Field>
                <Label>Charges</Label>
                <Input
                  type="number"
                  value={formState.charges}
                  onChange={(e) => {
                    if (!isNaN(Number(e.target.value)) && Number(e.target.value) < 0) {
                      return;
                    }
                    setFormState(() => ({
                      ...formState,
                      charges: Number(e.target.value),
                    }));
                  }}
                  className={clsx(
                    `${theme === "light"
                      ? "border border-gray-500/30"
                      : "border border-stone-800/25"
                    }`,
                    "mt-1 block w-full rounded-lg bg-white/5 py-1.5 px-3 text-sm/6",
                    "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-blue-500"
                  )}
                  required
                />
              </Field>
              <ChargesCalculator
                setFormState={setFormState}
                type="SELL"
                transactionPrice={Number(formState.price)}
                quantity={formState?.quantity}
              />
              <ButtonExit isLoading={isLoading} />
            </Fieldset>
          </form>
        </div>
      </Drawer>
    </>
  );
}
