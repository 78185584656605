import Cookies, { CookieSetOptions } from "universal-cookie";

const cookiesconf = new Cookies(null, { path: "/" });
function SetCookies({
  name,
  value,
  option,
}: {
  name: string;
  value: string;
  option?: CookieSetOptions;
}) {
  cookiesconf.set(name, value, option);
}
function GetCookies({ name }: { name: string }) {
  cookiesconf.get(name);
}

function useUserData() {
  const userData = cookiesconf?.get("access")?.valueOf();
  return userData;
}

const cookies = new Map();
cookies.set(
  "Set",
  (props: { name: string; value: string; option?: CookieSetOptions }) =>
    SetCookies(props)
);
cookies.set("Get", (props: { name: string }) => GetCookies(props));
cookies.set("getuser", () => useUserData());

export default cookies;
