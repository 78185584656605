import { useEffect, useState } from "react";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import dashboard from "./dashboard.json";
import cookies from "../../../Helpers/Cookies";
import { useNavigate } from "react-router-dom";
import { SERVER_URL } from "../../../Config/Constants";
import { useToastStore } from "../../../context/ThemeState";
import localStorageHelpers from "../../../Helpers/LocalStorage";
import DashboardExplained from "../Documentations/DashboardDocs";

export default function DashboardMain() {
  const navigate = useNavigate()
  const [journalDetails, setJournal] = useState<any>({})
  const { showToast } = useToastStore()
  useEffect(() => {
    const user = cookies.get("getuser")();
    if (!user) {
      navigate("/auth/signin");
    }
    (async () => {
      try {
        const journal_id = localStorageHelpers.get("getdata")("journal_id")
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        const response = await fetch(
          `${SERVER_URL}/api/journals/id/${journal_id}`,
          { headers }
        );
        if (response.ok) {
          const responseData = await response.json()
          setJournal(responseData);
        } else {
          const responseData = await response.json()
          showToast(responseData.message || responseData.title, "error");
        }
      } catch (error: any) {
        showToast(error.message || error.title, "error");
      }
    })();
  }, [showToast])
  return (
    <div className="flex flex-col gap-5 px-5 py-5">
      <div>
        <div className="flex items-center justify-between">
          <p className="font-bold text-xl">Position Manager</p>
          <DashboardExplained />
        </div>
        <Section1 Journal={journalDetails} />
      </div>
      <div>
        <p className="font-bold text-xl">Overview</p>
        <Section2 Journal={journalDetails} />
      </div>
      <Section3 data={dashboard} />
      <Section4 />
    </div>
  );
}
