import {Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import React from "react";
import { useThemeStore } from "../../../context/ThemeState";
import { XMarkIcon } from "@heroicons/react/16/solid";

export default function ModalHeadless({
  isOpen,
  close,
  children,
  title,
}: {
  isOpen: boolean;
  close: () => void;
  children: React.ReactNode;
  title: string;
}) {
  const { theme } = useThemeStore();
  return (
    <>
      <Dialog
        open={isOpen}
        as="div"
        className="relative z-10 focus:outline-none"
        onClose={close}
        __demoMode
      >
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto backdrop-blur-xl">
          <div className="flex min-h-full items-center justify-center p-4">
            <DialogPanel
              transition
              className={`w-full max-w-md rounded-xl p-6 backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0 relative shadow-2xl ${
                theme === "light" ? "bg-white/75" : "bg-white/5"
              }`}
            >
              <p
                className=" absolute right-0 top-0 p-5 cursor-pointer"
                onClick={close}
              >
                <XMarkIcon className="h-6 w-6 text-red-500" />
              </p>
              <DialogTitle
                as="h3"
                className={`text-lg font-medium ${
                  theme === "light" ? "text-black" : "text-white"
                }`}
              >
                {title}
              </DialogTitle>
              {children}
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </>
  );
}
