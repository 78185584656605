import FeedList from "./FeedList";
import SideControll from "./SideControll";

export default function FeedContainer() {
  return (
    <div className="md:flex md:flex-row flex-col gap-5 w-full md:px-0 px-3">
      <SideControll />
      <div className="md:ml-36 lg:ml-52 w-full md:m-3">
        <FeedList />
      </div>
    </div>
  );
}
