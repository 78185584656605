// import Feeds from "./Feed.json";
import { useEffect, useState } from "react";
import FeedCard from "./FeedCard";
import { useToastStore } from "../../../context/ThemeState";
import { SERVER_URL } from "../../../Config/Constants";
import localStorageHelpers from "../../../Helpers/LocalStorage";

export default function FeedList() {
  const { showToast } = useToastStore();
  const [data, setData] = useState([]);
  useEffect(() => {
    (async () => {
      try {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        const journal_id = localStorageHelpers.get("getdata")("journal_id");
        const response = await fetch(
          `${SERVER_URL}/api/feeds/journal/${journal_id}`,
          { headers }
        );
        if (response.ok) {
          const responseData = await response.json();
          setData(responseData.content);
        } else {
          const responseData = await response.json();
          showToast(responseData.message, "error");
        }
      } catch (error: any) {
        showToast(error.message, "error");
      }
    })();
  }, []);
  return (
    <div className="flex flex-col gap-3 w-full">
      {data?.map((item: any, index: number) => (
        <div key={index} className="w-full">
          <FeedCard Feed={item} />
        </div>
      ))}
    </div>
  );
}
