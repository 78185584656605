import React from "react";
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { AdjustmentsHorizontalIcon } from "@heroicons/react/20/solid";

function Dropdown() {
    return (
        <div className="relative inline-block text-left">
            <Menu>
                {({ open }) => (
                    <>
                        <div>
                            <MenuButton className="text-sm font-medium rounded-md shadow-sm ">
                                <AdjustmentsHorizontalIcon className="h-5 w-5" />
                            </MenuButton>
                        </div>

                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <MenuItems
                                className="absolute right-0 top-0 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg z-[2300]"
                                style={{
                                    transformOrigin: "top center",
                                }}
                            >
                                <div className="px-2 py-1.5 text-sm font-semibold text-gray-800">UTIAMC</div>
                                <div className="my-1 h-px bg-gray-200"></div>
                                <div>
                                    <MenuItem>
                                        {({ active }) => (
                                            <button
                                                className={`block w-full text-left px-3 py-1.5 text-sm text-gray-700 ${active ? "bg-gray-200" : ""
                                                    } hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500`}
                                            >
                                                Edit
                                            </button>
                                        )}
                                    </MenuItem>
                                </div>
                                <div>
                                    <MenuItem>
                                        {({ active }) => (
                                            <button
                                                className={`block w-full text-left px-3 py-1.5 text-sm text-red-600 ${active ? "bg-gray-200" : ""
                                                    } hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-red-500`}
                                            >
                                                Delete
                                            </button>
                                        )}
                                    </MenuItem>
                                </div>
                            </MenuItems>
                        </Transition>
                    </>
                )}
            </Menu>
        </div>
    );
}

export default Dropdown;
