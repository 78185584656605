export class CallBacks {
  async GET(url: string, { headers }: { headers: Headers }) {
    try {
      const response = await fetch(`${url}`, {
        method: "GET",
        headers,
      });
      if (response.ok) {
        return response.json();
      } else {
        throw response.json();
      }
    } catch (error) {
      throw error;
    }
  }
  async POST(
    url: string,
    { headers, body }: { headers: Headers; body: string }
  ) {
    try {
      const response = await fetch(`${url}`, {
        method: "POST",
        headers,
        body,
      });
      console.log(response.status);
      if (response.ok) {
        return await response.json();
      } else {
        throw await response.json();
      }
    } catch (error) {
      throw error;
    }
  }
}

export const SERVER_ENDPOINT = "http://localhost:4000";
