import { Button } from "@headlessui/react";
import { TrashIcon } from "@heroicons/react/16/solid";
import {
  ArrowsPointingInIcon,
  ArrowsRightLeftIcon,
  ArrowTrendingUpIcon,
  DocumentIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";
import { useThemeStore } from "../../../context/ThemeState";
import { Link } from "react-router-dom";
export function TypeConverter({
  type,
  symbol,
}: {
  symbol: string;
  type:
    | "ORDER"
    | "CLOSE_ORDER"
    | "NOTE"
    | "NOTES_JOURNAL"
    | "CHART"
    | "CHARTS_JOURNAL";
}) {
  return (
    <div>
      {type === "ORDER" ? (
        <div>
          <h3 className="tracking-tight flex gap-2 font-semibold text-md">
            <ArrowsRightLeftIcon className="h-5 w-5" />
            <span className="font-semibold">New Order for {symbol}</span>
          </h3>
        </div>
      ) : type === "CLOSE_ORDER" ? (
        <div>
          <h3 className="tracking-tight flex gap-2 font-semibold text-md">
            <ArrowsPointingInIcon className="h-5 w-5" />
            <span className="font-semibold">New Close Order for {symbol}</span>
          </h3>
        </div>
      ) : type === "NOTE" ? (
        <div>
          <h3 className="tracking-tight flex gap-2 font-semibold text-md">
            <DocumentIcon className="h-5 w-5" />
            <span className="font-semibold">New Note for {symbol}</span>
          </h3>
        </div>
      ) : type === "CHARTS_JOURNAL" ? (
        <div>
          <h3 className="tracking-tight flex gap-2 font-semibold text-md">
            <ArrowTrendingUpIcon className="h-5 w-5" />
            <span className="font-semibold">New Order for {symbol}</span>
          </h3>
        </div>
      ) : type === "CHART" ? (
        <div>
          <h3 className="tracking-tight flex gap-2 font-semibold text-md">
            <ArrowTrendingUpIcon className="h-5 w-5" />
            <span className="font-semibold">New Order for {symbol}</span>
          </h3>
        </div>
      ) : (
        <div>
          <h3 className="tracking-tight flex gap-2 font-semibold text-md">
            <ArrowTrendingUpIcon className="h-5 w-5" />
            <span className="font-semibold">New Order for {symbol}</span>
          </h3>
        </div>
      )}
    </div>
  );
}

export function TypeButtons({
  type,
  orderId,
}: {
  orderId: string;
  type:
    | "ORDER"
    | "CLOSE_ORDER"
    | "NOTE"
    | "NOTES_JOURNAL"
    | "CHART"
    | "CHARTS_JOURNAL";
}) {
  const { theme } = useThemeStore();
  return (
    <div>
      {type === "ORDER" ? (
        <>
          <Link to={`/journal/trades/${orderId}?ref=feed`}>
            <Button
              className={`inline-flex items-center justify-center text-sm font-medium transition-colors gap-2 
              focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-7 
              focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none 
              ring-offset-accent-1 border h-6 px-2 rounded-md ${
                theme === "light"
                  ? "border-gray-300 hover:bg-gray-200"
                  : "border-stone-800 hover:bg-stone-800"
              }`}
            >
              View Details
            </Button>
          </Link>
        </>
      ) : type === "CLOSE_ORDER" ? (
        <>
          <Link to={`/journal/trades/${orderId}?ref=feed`}>
            <Button
              className={`inline-flex items-center justify-center text-sm font-medium transition-colors gap-2 
              focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-7 
              focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none 
              ring-offset-accent-1 border h-6 px-2 rounded-md ${
                theme === "light"
                  ? "border-gray-300 hover:bg-gray-200"
                  : "border-stone-800 hover:bg-stone-800"
              }`}
            >
              View Details
            </Button>
          </Link>
        </>
      ) : type === "NOTE" ? (
        <div className="flex items-center gap-3">
          <Link to={`/journal/trades/${orderId}?ref=feed`}>
            <Button
              className={`inline-flex items-center justify-center text-sm font-medium transition-colors gap-2 
              focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-7 
              focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none 
              ring-offset-accent-1 border h-6 px-2 rounded-md ${
                theme === "light"
                  ? "border-gray-300 hover:bg-gray-200"
                  : "border-stone-800 hover:bg-stone-800"
              }`}
            >
              View Details
            </Button>
          </Link>
          <Button
            className={`inline-flex items-center justify-center text-sm font-medium transition-colors gap-2 
          focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-7 
          focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none 
          ring-offset-accent-1 border h-6 px-2 rounded-md ${
            theme === "light"
              ? "border-gray-300 hover:bg-gray-200"
              : "border-stone-800 hover:bg-stone-800"
          }`}
          >
            <PencilSquareIcon className="h-5 w-5 " /> Edit
          </Button>
          <Button
            className={`inline-flex items-center justify-center text-sm font-medium transition-colors text-red-500 gap-2 
          focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-7 
          focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none 
          ring-offset-accent-1 border text-gray-12 hover:bg-gray-a4 h-6 px-2 rounded-md ${
            theme === "light"
              ? "border-red-300 hover:bg-red-200"
              : "border-red-800 hover:bg-red-800"
          }`}
          >
            <TrashIcon className="h-5 w-5 " /> Delete
          </Button>
        </div>
      ) : type === "CHARTS_JOURNAL" ? (
        <>
          <Link to={`/journal/trades/${orderId}?ref=feed`}>
            <Button
              className={`inline-flex items-center justify-center text-sm font-medium transition-colors 
          focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-7 
          focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none 
          ring-offset-accent-1 border h-6 px-3 rounded-md ${
            theme === "light"
              ? "border-gray-300 hover:bg-gray-200"
              : "border-gray-700 hover:bg-stone-800"
          }`}
            >
              View Details
            </Button>
          </Link>
        </>
      ) : type === "CHART" ? (
        <div className="flex items-center gap-3">
          <Link to={`/journal/trades/${orderId}?ref=feed`}>
            <Button
              className={`inline-flex items-center justify-center text-sm font-medium transition-colors 
          focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-7 
          focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none 
          ring-offset-accent-1 border h-6 px-3 rounded-md ${
            theme === "light"
              ? "border-gray-300 hover:bg-gray-200"
              : "border-gray-700 hover:bg-stone-800"
          }`}
            >
              View Details
            </Button>
          </Link>
          <Button
            className={`inline-flex items-center justify-center text-sm font-medium transition-colors gap-2 
          focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-7 
          focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none 
          ring-offset-accent-1 border h-6 px-2 rounded-md ${
            theme === "light"
              ? "border-gray-300 hover:bg-gray-200"
              : "border-gray-700 hover:bg-stone-800"
          }`}
          >
            <PencilSquareIcon className="h-5 w-5 " /> Edit
          </Button>
          <Button
            className={`inline-flex items-center justify-center text-sm font-medium transition-colors gap-2 
          focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-7 
          focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none 
          ring-offset-accent-1 border h-6 px-2 rounded-md ${
            theme === "light"
              ? "border-red-300 hover:bg-red-200 text-red-500"
              : "border-red-700 hover:bg-red-900 text-red-500"
          }`}
          >
            <TrashIcon className="h-5 w-5 " /> Delete
          </Button>
        </div>
      ) : (
        <>
          <Link to={`/journal/trades/${orderId}?ref=feed`}>
            <Button
              className={`inline-flex items-center justify-center text-sm font-medium transition-colors gap-2 
              focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-7 
              focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none 
              ring-offset-accent-1 border h-6 px-2 rounded-md ${
                theme === "light"
                  ? "border-gray-300 hover:bg-gray-200"
                  : "border-stone-800 hover:bg-stone-800"
              }`}
            >
              View Details
            </Button>
          </Link>
        </>
      )}
    </div>
  );
}
