import { Link } from "react-router-dom"; // or 'next/link' if using Next.js

const ProfilePage = () => {
  return (
    <div className="h-full px-2">
      <div className="flex flex-col gap-2 p-2">
        <h1 className="font-semibold">Licenses</h1>
        <div className="border border-b-gray-600"></div>
        <div className="grid grid-cols-3 gap-2">
          <div className="rounded-lg border bg-gray-200 text-gray-800 shadow-sm">
            <div className="flex p-3 text-sm font-bold whitespace-nowrap">
              <h3 className="text-2xl font-semibold leading-none tracking-tight">
                Journal - India
              </h3>
            </div>
            <div className="p-3 pt-0">
              <p>Expires in 7 months 16 days</p>
            </div>
          </div>
        </div>

        <h1 className="font-semibold mt-4">Profile</h1>
        <div className="border border-b-gray-600"></div>
        <div className="flex flex-row gap-2">
          <span>Email: </span>
          <span>akash.m.010@gmail.com</span>
        </div>
        <div className="flex flex-row gap-2">
          <span>Name: </span>
          <span>Akash More</span>
        </div>
        <div className="flex flex-row gap-2">
          <span>Phone: </span>
          <span>9762731154</span>
        </div>

        <h1 className="font-semibold mt-4">Data Backup</h1>
        <div className="border border-b-gray-600"></div>
        <div className="flex flex-col items-start gap-2">
          <button className="inline-flex items-center justify-center rounded-md text-sm transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-700 focus-visible:ring-offset-2 bg-blue-600 text-white font-bold hover:bg-blue-700 h-8 py-2 px-4">
            <Link to="/journal/e1eaba19-e7fe-4055-88dc-91d7bc73a297/backup">
              Download Journal - India
            </Link>
          </button>

          <form
            method="post"
            action="/journal/e1eaba19-e7fe-4055-88dc-91d7bc73a297/profile"
            className="flex gap-x-2 items-center"
            encType="multipart/form-data"
          >
            <input
              type="file"
              className="flex h-10 rounded-md border border-gray-700 bg-transparent text-gray-800 px-3 py-2 text-sm placeholder:text-gray-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-700 focus-visible:ring-offset-2"
              name="backup"
              id="backup"
              accept=".json"
            />
            <button
              className="inline-flex items-center justify-center rounded-md text-sm transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-700 focus-visible:ring-offset-2 bg-blue-600 text-white font-bold hover:bg-blue-700 h-8 py-2 px-4"
              type="submit"
              name="action"
              value="RESTORE"
            >
              Restore &nbsp;
            </button>
          </form>
        </div>

        <h1 className="font-semibold mt-4">Account</h1>
        <div className="border border-b-gray-600"></div>
        <div className="flex gap-2">
          <Link
            className="items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-700 focus-visible:ring-offset-2 border border-gray-700 text-gray-800 hover:bg-gray-300 h-8 py-2 px-4 flex gap-2"
            to="/journal/e1eaba19-e7fe-4055-88dc-91d7bc73a297/share"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="w-5 h-5"
            >
              <path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"></path>
              <polyline points="16 6 12 2 8 6"></polyline>
              <line x1="12" x2="12" y1="2" y2="15"></line>
            </svg>
            Share
          </Link>
          <form method="post" action="/resources/logout">
            <button className="items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-700 focus-visible:ring-offset-2 border border-gray-700 text-gray-800 hover:bg-gray-300 h-8 py-2 px-4 flex gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="h-5 w-5"
              >
                <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                <polyline points="16 17 21 12 16 7"></polyline>
                <line x1="21" x2="9" y1="12" y2="12"></line>
              </svg>
              <span>Logout</span>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
