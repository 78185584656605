import { FormEvent, useRef, useState } from "react";
import { useThemeStore } from "../../context/ThemeState";
import SignInButton from "../UI/SignInButton";
import { Link, useNavigate } from "react-router-dom";
import { CallBacks } from "../../Config/Callbacks";

import Redirecting from "./RedirectingPage";
import toastContainer from "../../context/ToastConfiguration";
import cookies from "../../Helpers/Cookies";
import GoogleAuth from "./GoogleAuthButton";
import { SERVER_URL } from "../../Config/Constants";

export default function SignIn() {
  const theme = useThemeStore((state) => state.theme);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const navigate = useNavigate();
  const [requestStatus, setRequestStatus] = useState({
    type: "",
    message: "",
  });
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const formData = {
        email: formRef.current?.username?.value,
        password: formRef.current?.password?.value,
      };
      const Callbacks = new CallBacks();
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      const response = await Callbacks.POST(`${SERVER_URL}/api/auth/login`, {
        headers,
        body: JSON.stringify(formData),
      });
      setIsSuccess(true);
      if (response) {
        setTimeout(() => {
          setIsSuccess(false);
          navigate("/journals", { state: { fromDashboard: true } });
        }, 1000);
      }
      cookies.get("Set")({
        name: "access",
        value: JSON.stringify(response.user),
        option: { path: "/" },
      });
      setRequestStatus({
        type: "SUCCESS",
        message: response.message,
      });
    } catch (error: any) {
      setRequestStatus({
        type: "ERROR",
        message: error.message || error.title,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className={`flex items-center justify-between h-screen w-screen ${theme === "light"
        ? "bg-white text-stone-800"
        : "bg-stone-950 text-stone-200"
        }`}
    >
      <form
        className="flex flex-col items-center lg:w-[40vw] md:w-[400px] gap-5 w-screen"
        onSubmit={handleSubmit}
        ref={formRef}
      >
        {requestStatus?.type !== "" && (
          <div className="w-[300px]">
            {toastContainer.get(requestStatus.type.toUpperCase())({
              message: requestStatus.message,
              close: () => setRequestStatus({ message: "", type: "" }),
            })}
          </div>
        )}
        <div>
          <h1 className="min-w-[300px] font-bold text-3xl">Sign In</h1>
          <p className="text-gray-400">
            You can access this resource only if you
          </p>
          <p className="text-gray-400"> have a subscription of the course</p>
          <p className="text-md"> Don&apos;t have an account? <Link to={"/auth/signup"} className="text-blue-700 underline">Signup</Link> here</p>
        </div>
        <div className="flex flex-col justify-center gap-3">
          <label htmlFor="username" className="font-[500]">
            Username
          </label>
          <input
            type="text"
            className={`px-5 py-1.5 border rounded-lg min-w-[300px] outline-none ${theme === "light"
              ? "bg-white border-gray-400 focus:ring-4 focus:ring-cyan-100 focus:border-cyan-600 "
              : "bg-stone-900 border-stone-800 focus:ring-4 focus:ring-cyan-950 focus:border-cyan-600 "
              }`}
            id="username"
            name="username"
            placeholder="xyz@example.com"
            required
          />
        </div>
        <div className="flex flex-col justify-center gap-3">
          <label htmlFor="password" className="font-[500]">
            Password
          </label>
          <input
            type="password"
            className={`px-5 py-1.5 border rounded-lg min-w-[300px] outline-none flex items-center ${theme === "light"
              ? "bg-white border-gray-400 focus:ring-4 focus:ring-cyan-100 focus:border-cyan-600"
              : "bg-stone-900 border-stone-800 focus:ring-4 focus:ring-cyan-950 focus:border-cyan-600 "
              }`}
            id="password"
            name="password"
            placeholder="**********"
            required
          />
        </div>
        <Link to={"/auth/forgetpassword"}>
          <p className="min-w-[300px] font-semibold underline">
            Forgot Password
          </p>
        </Link>
        <div>
          <SignInButton isLoading={isLoading} btnName="Submit" />
        </div>
        <div>
          <GoogleAuth />
        </div>
      </form>
      <div className="lg:block hidden">
        <img
          src="https://miro.medium.com/v2/resize:fit:602/1*xrC5eS4VJs59Sm8ZooQgHA.jpeg"
          alt="Stock Market"
          className="h-screen lg:py-3 lg:px-3 lg:rounded-[40px] "
        />
      </div>
      <Redirecting isSuccess={isSuccess} />
    </div>
  );
}
