import { useState } from "react";
import { useThemeStore } from "../../context/ThemeState";
import JournalIcon from "../UI/Icons/Journals/JournalIcon";
import MapIcon from "../UI/Icons/Journals/MapIcon";
import ExtendValidity from "./ExtendValidity.modal";
import Edit from "./Edit.modal";
import { Link, useNavigate } from "react-router-dom";
import localStorageHelpers from "../../Helpers/LocalStorage";
import { Journal } from "../../types/types";

export default function JournalCard({ journalData }: { journalData: Journal }) {
  const theme = useThemeStore((state) => state.theme);
  const navigate = useNavigate()
  const [isOpenExtendValidity, setIsOpenExtendValidity] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);

  function openjournal(journal_id: string) {
    localStorageHelpers.get("storedata")("journal_id", journal_id);
    localStorageHelpers.get("storedata")("journal_name", journalData?.name);
    navigate("/journal/dashboard")
  }

  return (
    <div
      className={`md:mx-10 mx-5 border p-5 rounded-[30px] flex md:flex-row flex-col  md:items-center justify-between my-5 ${theme === "light" ? "border-stone-100" : "border-stone-900"
        }`}
    >
      <div className="flex items-center md:gap-10 gap-5">
        <p
          className={`w-max p-2 rounded-2xl ${theme === "light" ? "bg-gray-100" : "bg-stone-900"
            }`}
        >
          <JournalIcon iconStyles="h-20 w-20" />
        </p>
        <div>
          <p className="font-semibold">{journalData?.name}</p>
          <div className="flex items-center gap-5">
            <div
              className={`${theme === "light" ? "text-gray-700" : "text-stone-500"
                } flex items-center`}
            >
              <p>
                <MapIcon sizeStyle="h-5 w-5" />
              </p>

              <p>India</p>
            </div>
            <p
              className={`w-max ${theme === "light"
                ? "bg-green-100 px-5 py-1 rounded-full font-semibold text-green-500"
                : "bg-green-950 px-5 py-1 rounded-full font-semibold text-green-500"
                }`}
            >
              {journalData?.licenses?.activated ? "Active" : "Inactive"}
            </p>
          </div>
          <p className="text-gray-400 text-sm">
            Expires At :
            {new Date(journalData?.licenses?.expiresAt).toLocaleString()}
          </p>
        </div>
      </div>
      <div className="flex items-items justify-end gap-5 md:mt-0 mt-5">
        <button
          className={`font-semibold px-3 py-1.5 rounded-lg ${theme === "light"
            ? "text-blue-500 border border-gray-300 hover:bg-gray-100"
            : "text-blue-500 bg-opacity-25 border border-gray-800 hover:bg-stone-900"
            }`}
          onClick={() => setIsOpenExtendValidity(true)}
        >
          Extend Validity
        </button>
        <button
          className={`font-semibold px-3 py-1.5 rounded-lg ${theme === "light"
            ? "text-blue-500 border border-gray-300 hover:bg-gray-100"
            : "text-blue-500 bg-opacity-25 border border-gray-800 hover:bg-stone-900"
            }`}
          onClick={() => setIsOpenEdit(true)}
        >
          Edit
        </button>
        <button
          onClick={() => openjournal(journalData.id)}
          className={`font-semibold px-5 py-1.5 ${theme === "light"
            ? "text-blue-500 bg-blue-100"
            : "text-blue-500 bg-blue-950 bg-opacity-25"
            }`}
        >
          Open
        </button>
      </div>
      <ExtendValidity isOpen={isOpenExtendValidity}>
        {<Subscription setIsOpen={() => setIsOpenExtendValidity(false)} />}
      </ExtendValidity>
      <ExtendValidity isOpen={isOpenEdit}>
        <Edit setIsopen={() => setIsOpenEdit(false)} />
      </ExtendValidity>
    </div>
  );
}

function Subscription({ setIsOpen }: { setIsOpen: () => void }) {
  const { theme } = useThemeStore();
  return (
    <div
      className={` rounded-xl ${theme === "light" ? "bg-white" : "bg-stone-900"
        } md:w-[400px] w-[90vw]`}
    >
      <div
        className={
          theme === "light"
            ? "bg-yellow-400 rounded-t-xl px-5 py-1.5 font-semibold text-lg relative"
            : "bg-yellow-700 rounded-t-xl px-5 py-1.5 font-semibold text-lg relative"
        }
      >
        <p>Extend Validity</p>
        <button
          className={`absolute top-0 right-0 px-5 py-1.5 text-lg rounded-tr-xl ${theme === "light"
            ? "bg-red-500 text-white"
            : "bg-red-500 text-white"
            }`}
          onClick={setIsOpen}
        >
          X
        </button>
      </div>
      <div className="w-full p-5 ">
        <div className="flex items-center font-semibold gap-3">
          <p className="">Name {" : "}</p>
          <p className="text-lg">India</p>
        </div>
        <div className="flex items-center font-semibold gap-3">
          <p className="">Country {" : "}</p>
          <MapIcon sizeStyle="h-5 w-5" />
          <p className="text-lg">India</p>
        </div>
        <form action="">
          <div className="flex flex-col items-center gap-5">
            <p className="font-bold text-center mt-5">Choose Subscriptions</p>
            <div className="flex items-center gap-5">
              <div
                className={`flex flex-col items-center border p-5 rounded-2xl w-[140px] ${theme === "light" ? "border-gray-300" : "border-stone-700"
                  }`}
              >
                <div className="flex items-center gap-2 mb-3">
                  <input
                    type="checkbox"
                    name="$94000"
                    id=""
                    className="h-5 w-5"
                  />
                  <p className="font-bold text-xl">$ 9440</p>
                </div>
                <p className="font-semibold">Per Journal</p>
                <p className="font-semibold">For 1 Year</p>
              </div>
              <div
                className={`flex flex-col items-center p-5 rounded-2xl w-[140px] ${theme === "light" ? "bg-green-200" : "bg-green-900"
                  }`}
              >
                <div className="flex items-center gap-2 mb-3">
                  <input
                    type="checkbox"
                    name="$94000"
                    id=""
                    className="h-5 w-5"
                  />
                  <p className="font-bold text-xl">$94000</p>
                </div>
                <p className="font-semibold">Per Journal</p>
                <p className="font-semibold">For Lifetime</p>
              </div>
            </div>
            <button
              className={`text-lg font-semibold w-[300px] rounded-xl px-4 py-2 ${theme === "light" ? "bg-yellow-400" : "bg-yellow-700"
                }`}
            >
              Subscribe
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
